/* eslint-disable prefer-destructuring */

import axios from 'axios';
import helpers from '@/traincom/mixins/helpers';

const state = {
  a: {},
  ex_accounts: [],
  selected_ex_account: {},
  monthCourses: [],
};

const getters = {
  curr_acc: () => state.a,
  admins: () => state.a.members,
  acc_owner: () => state.a.user_id,
  selected_ex_account: () => state.selected_ex_account,
  ex_accounts: () => state.ex_accounts,
  loading: () => state.loading,
  getMonthCourses: () => state.monthCourses,
};

const actions = {
  async load(context) {
    const result = await axios.get(`${helpers.apihost()}get_account`);
    context.commit('set_account', result.data[0]);
  },
  async load_ex_accounts(context) {
    const result = await axios.get(`${helpers.apihost()}get_ex_accounts`);
    context.commit('set_ex_accounts', result.data);
    return result.data;
  },
  async loadMonthCourses(context, date) {
    const result = await axios.get(`${helpers.apihost()}loadMonthCourses/${date}`);
    context.commit('set_monthCourses', result.data);
    return result.data;
  },
  async add_account(context, payload) {
    const result = await axios.post(`${helpers.apihost()}add_account`, payload);
    if (result) {
      return true;
    }
    return false;
  },
  async editAccess(context, payload) {
    console.log(payload);
    const result = await axios.post(`${helpers.apihost()}editAccess`, {
      params: {
        accessAllowed: payload,
        acct_id: state.selected_ex_account.id,
      },
    });
    if (result) {
      return true;
    }
    return false;
  },
  async change_rights(context, payload) {
    const result = await axios.post(`${helpers.apihost()}change_rights`, {
      params: {
        payload,
      },
    });
    if (result) {
      return true;
    }
    return false;
  },
  async del_user(context, payload) {
    const user = {
      userId: payload,
      rights: {
        acc: false,
        group: false,
        training: false,
        assignment: false,
      },
    };
    context.dispatch('change_rights', user);
    const result = await axios.post(`${helpers.apihost()}del_acc_user`, {
      params: {
        userId: payload,
      },
    });
    if (result) {
      context.dispatch('load');
      return true;
    }
    return false;
  },
};

const mutations = {
  set_account(s, data) {
    state.a = data;
  },
  set_ex_accounts(s, data) {
    state.ex_accounts = data;
    if (state.selected_ex_account === []) {
      state.selected_ex_account = data[0];
    }
  },
  set_monthCourses(s, data) {
    state.monthCourses = data;
  },
  change_selected_acc(s, account) {
    state.selected_ex_account = account;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
