/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import axios from 'axios';
import helpers from '@/traincom/mixins/helpers';

const state = {
  loading: true,
  unit_done: false,
  went_back: false,
  a: {
    meta: {
      version: 0,
      values: [],
      logo1: '',
      logo2: '',
      company_name: '',
    },
    max_unit: 0,
    curr_unit: 0,
    curr_page: 0,
    unit_progress: null,
    page_progress: null,
    name: '',
    subtitle: '',
    techname: '',
    desc: '',
    creator_u: null,
    creator_a: null,
    status: 0,
    new_card: true,
    user_id: null,
    grade: null,
    difficulty: 0,
    type: '',
    units: [
      {
        meta: {
          index: 0,
          state: '',
          utype: '',
          title: 'Titel',
          title2: 'Titel 2',
          time: '',
        },
        pages: [],
      },
    ],
    resources: [],
  },
};

const getters = {
  get_course: () => state.a,
  type: () => state.a.type,
  settings: () => state.a.settings,
  max_unit: () => state.a.max_unit,
  loading: () => state.loading,
  princi: () => state.a.units.filter((units) => units.meta.utype === 'train' && units.meta.title !== 'Vorabanalyse'),
  anchors: () => state.a.units.filter((units) => units.meta.utype === 'anchor'),
  page_x: () => (pri, pa) => state.a.units[pri].pages[pa],
  curr_pagee: () => state.a.units[state.a.curr_unit].pages[state.a.curr_page],
  curr_page: () => {
    if (state.a.units[state.a.curr_unit].pages[state.a.curr_page]) {
      return state.a.units[state.a.curr_unit].pages[state.a.curr_page];
    }
    return {};
  },
  curr_unit: () => state.a.curr_unit,
  curr_page_nr: () => state.a.curr_page,
  curr_q_nr: () => {
    if (state.a.user_id === null) return null;
    let c = 0;
    const vtypes = ['questions', 'pquestion'];
    for (let i = 0; i < state.a.curr_page + 1; i += 1) {
      if (vtypes.indexOf(state.a.units[state.a.curr_unit].pages[i].type) > -1) {
        c += 1;
      }
    }
    return c;
  },
  page_length: () => state.a.units[state.a.curr_unit].pages.length,
  curr_unit_obj: () => state.a.units[state.a.curr_unit],
  unit_pages: () => (i) => state.a.units[i].pages,
  units: () => state.a.units,
  units_length: () => state.a.units.length,
  went_back: () => {
    if (state.a.curr_page > 0) {
      if (state.a.units[state.a.curr_unit].pages[state.a.curr_page - 1].type === 'test') {
        return true;
      }
    }
    if (state.went_back || state.a.curr_page === 0) {
      return true;
    }
    return false;
  },
  tab_pos: () => {
    if (state.a.units[state.a.curr_unit].meta.utype === 'anchor') {
      return 1;
    }
    return 0;
  },
  curr_page_type: () => {
    if (state.unit_done || state.a.units[state.a.curr_unit].meta.state === 'fa fa-lock') {
      return 'endpage';
    }
    if (state.a.status === 12) return 'endpage';
    if (state.a.units[state.a.curr_unit].pages[state.a.curr_page]) {
      return state.a.units[state.a.curr_unit].pages[state.a.curr_page].type;
    }
    return {};
  },
  curr_course_type: () => state.a.course_type,
  curr_course: () => state.a,
  status: () => state.a.status,
  get_pagee: () => (i) => state.a.units[state.a.curr_unit].pages[i],
  get_page: () => (i) => {
    if (state.a.units[state.a.curr_unit].pages[i]) {
      return state.a.units[state.a.curr_unit].pages[i];
    }
    console.log('UN - DEFINED');
    return '{}';
  },
  curr_page_type_x: () => {
    if (state.a.user_id === null) return null;
    return state.a.units[state.a.curr_unit].pages[state.a.curr_page].type;
  },
  get_q_count: () => {
    let c = 0;
    const vtypes = ['questions', 'pquestion'];
    for (let i = 0; i < state.a.units[state.a.curr_unit].pages.length; i += 1) {
      if (vtypes.indexOf(state.a.units[state.a.curr_unit].pages[i].type) > -1) {
        c += 1;
      }
    }
    return c;
  },
  qcards: () => {
    const cards = [];
    let limit = state.a.curr_unit;
    if (state.a.status === 12) {
      limit += 1;
    }
    if (state.a.status < 10) limit = 10; // evtl. limit = state.a.units.length ?
    for (let i = 0; i < limit; i += 1) {
      if (state.a.status >= 10) {
        if (state.a.units[i].meta.utype === 'train' && state.a.units[i].meta.title !== 'Vorabanalyse') {
          let animationName = '';
          if (i === state.a.curr_unit - 1 && state.a.new_card) {
            animationName = 'vanishIn';
            state.a.new_card = false;
          }
          cards.push({
            img: `${helpers.resource_path()}${state.a.techname}_hp${i + 1}.jpg`,
            pdf: `${helpers.resource_path()}${state.a.techname}_hp${i + 1}.pdf`,
            animation: `${animationName}`,
            title: state.a.units[i].meta.title,
            index: i,
          });
        }
      } else {
        cards.push({
          img: `${helpers.resource_path()}${state.a.techname}_hp${i + 1}.jpg`,
          pdf: `${helpers.resource_path()}${state.a.techname}_hp${i + 1}.pdf`,
        });
      }
    }
    return cards;
  },
  finished_units: () => {
    if (state.a.user_id === null) return null;
    const units = [];
    let start = 0;
    if (state.a.units[0].pages[0].type === 'check') {
      start = 1;
    }
    for (let i = start; i < state.a.max_unit; i += 1) {
      units.push({
        title: state.a.units[i].meta.title,
        title2: state.a.units[i].meta.title2,
        index: i,
        utype: state.a.units[i].meta.utype,
      });
    }
    return units;
  },
  train_done: () => {
    const nt = state.a.units.filter((units) => units.meta.utype === 'train').length;
    if (nt - 1 > state.a.curr_unit) {
      return false;
    }
    return true;
  },
  has_check: () => {
    if (state.a.user_id === null) return null;
    if (state.a.units[0].pages.length > 0) {
      if (state.a.units[0].pages[0].type === 'check') {
        return true;
      }
    }
    return false;
  },
};

const actions = {
  async load(context, p) {
    state.loading = true;
    state.a.curr_unit = 0;
    state.a.curr_page = 0;
    console.log('HI');
    console.log(`${helpers.apihost()}course/${p}`);
    const result = await axios.get(`${helpers.apihost()}course/${p}`);
    if (result) {
      context.commit('set_course', result.data);
    }
  },
  async save(context) {
    // if the last unit is done set status to done before save
    if (state.a.units[state.a.units.length - 1].meta.state === 'far fa-check-circle') {
      state.a.status = 12;
    }
    const result = await axios.put(`${helpers.apihost()}course`, state.a, {
      headers: {
        'Content-type': 'application/json',
      },
    });
    context.commit('set_id', result.data.id);
    context.commit('set_timestamp', result.data);
  },
  async save_course_status(context, payload) {
    const result = await axios.put(`${helpers.apihost()}save_course_status`, payload, {
      headers: {
        'Content-type': 'application/json',
      },
    });
    return result;
  },
  async test() {
    await axios.get(`${helpers.apihost()}test_course/${state.a.id}`);
  },
  async copy(context) {
    const r = await axios.get(`${helpers.apihost()}copy_course/${state.a.id}`);
    context.commit('set_course', r.data);
  },
  async deleteResource(context, payload) {
    const r = await axios.post(`${helpers.apihost()}deleteResource`, {
      params: {
        course_id: payload.course_id,
        path: payload.path,
      },
    });
    if (r) return r;
    return false;
  },
};

const mutations = {
  set_id(s, v) {
    state.a.id = v;
  },
  set_version(s, newVersion) {
    state.a.meta.version = newVersion;
  },
  reset_page() {
    state.a.curr_page = 0;
  },
  add_poster(s, poster) {
    state.posters.push(poster);
  },
  toggle_edit(s, payload) {
    state.a.status = payload;
  },
  reset_progress() {
    state.a.curr_unit = 0;
    state.a.curr_page = 0;
  },
  end_course() {
    state.unit_done = false;
  },
  set_course(s, payload) {
    state.a = payload;
    state.loading = false;
  },
  set_timestamp(s, payload) {
    if (state.a.status > 0) {
      const cu = state.a.curr_unit - 1;
      state.a.units[cu].meta.done = payload.units[cu].meta.done;
    }
  },
  select_chapter(s, index) {
    state.a.unit_progress = state.a.curr_unit;
    state.a.page_progress = state.a.curr_page;
    state.a.curr_unit = index;
    state.a.curr_page = 0;
  },
  editor_change_unit(s, index) {
    state.a.curr_page = 0;
    state.a.curr_unit = index;
  },
  return_to_progress() {
    state.a.curr_unit = state.a.unit_progress;
    state.a.curr_page = state.a.page_progress;
  },
  page_back() {
    if (state.went_back === false && state.a.units[state.a.curr_unit].pages.length > 1) {
      state.went_back = true;
      state.a.curr_page -= 1;
    }
  },
  end_page() {
    if (state.a.units[state.a.curr_unit].pages.length > state.a.curr_page + 1) {
      state.a.curr_page += 1;
      state.went_back = false;
    } else {
      state.a.max_unit += 1;

      let start = 0;
      if (state.a.units[0].pages[0].type === 'check') {
        start = 1;
      }
      if (state.a.curr_unit >= start && state.a.curr_unit !== 11) {
        state.unit_done = true;
      }
      if (state.a.curr_unit + 1 >= state.a.units.length) {
        state.a.units[state.a.curr_unit].meta.state = 'far fa-check-circle';
        state.a.new_card = true;
      }
      if (state.a.curr_unit + 1 < state.a.units.length) {
        state.a.units[state.a.curr_unit].meta.state = 'far fa-check-circle';
        state.a.curr_unit += 1;
        state.a.curr_page = 0;
        state.a.new_card = true;
        // const nt = state.a.units.filter((units) => units.meta.utype === 'train').length;
        const nt = state.a.units.length;
        if (nt > state.a.curr_unit && state.a.type !== 'mindtrigger') {
          state.a.units[state.a.curr_unit].meta.state = 'far fa-circle';
        }
      }
      state.a.new_card = true;
    }
    state.a.settings.rem_count = 0;
    const date = new Date(new Date().getFullYear(),
      new Date().getMonth(), new Date().getDate());
    state.a.settings.last_edit = date;
    if (state.a.settings.started === null && state.a.acct_id !== '5f94261eddfd78171c79ce0c') {
      state.a.settings.started = date;
    }
  },
  set_saturday(s, value) {
    state.a.settings.inc_sat = value;
  },
  set_sunday(s, value) {
    state.a.settings.inc_sun = value;
  },
  set_rem_freq(s, value) {
    state.a.settings.rem_freq = value;
  },
  set_status(status) {
    state.a.status = status;
  },
  add_page(s, obj) {
    state.a.units[state.a.curr_unit].pages.push(obj);
  },
  add_unit(s, obj) {
    if (obj.meta.utype === 'anchor') {
      state.a.units.push(obj);
      return;
    }
    const trainings = state.a.units.filter((units) => units.meta.utype === 'train');
    state.a.units.splice(trainings.length, 0, obj);
  },
  del_page(s, index) {
    state.a.units[state.a.curr_unit].pages.splice(index, 1);
  },
  del_unit(s, index) {
    state.a.units.splice(index, 1);
    state.a.units[0].meta.state = 'far fa-circle';
  },
  changePos(s, draggingRowIndex, draggingRow, droppedOnRowIndex, droppedOnRow) {
    state.a.units.splice(droppedOnRowIndex, 1, draggingRow);
    state.a.units.splice(draggingRowIndex, 1, droppedOnRow);
  },
  new_course(s, cType) {
    const u = this.getters['User/user'];
    let uType = '';
    if (cType === 'training') uType = 'train';
    if (cType === 'mindtrigger') uType = 'anchor';

    const obj = {
      meta: {
        values: [],
        logo1: '',
        logo2: '',
        company_name: '',
      },
      type: cType,
      max_unit: 0,
      curr_unit: 0,
      curr_page: 0,
      unit_progress: null,
      page_progress: null,
      name: 'name',
      subtitle: '',
      techname: '',
      desc: '',
      creator_u: null,
      creator_a: null,
      status: 0,
      new_card: true,
      user_id: u.id,
      grade: null,
      difficulty: 0,
      units: [
        {
          meta: {
            index: 0,
            state: 'far fa-circle',
            utype: uType,
            title: 'Titel',
            title2: 'Titel 2',
            mouse_over_text: '',
            time: '',
          },
          pages: [],
        },
      ],
    };
    state.a = obj;
  },
  clear_course() {
    const obj = {
      meta: {
        values: [],
        logo1: '',
        logo2: '',
        company_name: '',
      },
      type: '',
      max_unit: 0,
      curr_unit: 0,
      curr_page: 0,
      unit_progress: null,
      page_progress: null,
      name: 'name',
      subtitle: '',
      techname: '',
      desc: '',
      creator_u: null,
      creator_a: null,
      status: 0,
      new_card: true,
      user_id: '',
      grade: null,
      difficulty: 0,
      units: [
        {
          meta: {
            index: 0,
            state: 'far fa-circle',
            utype: '',
            title: 'Titel',
            title2: 'Titel 2',
            mouse_over_text: '',
            time: '',
          },
          pages: [],
        },
      ],
    };
    state.a = obj;
    state.unit_done = false;
    console.log('course cleared');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
