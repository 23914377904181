import axios from 'axios';
import helpers from '@/traincom/mixins/helpers';

const state = {
  loading: false,
  a: [],
  stats: [],
};
const getters = {
  get_assignment: () => state.a,
  is_loading: () => state.loading,
  get_courses: () => state.stats,
};
const actions = {
  async create_te() {
    const result = await axios.post(`${helpers.apihost()}create_te`, {
      params: {
        course_id: '607c45d7aef27f4385b59796',
      },
    });
    console.log(result);
  },
  create_array() {
    axios.post(`${helpers.apihost()}create_array`, {
      params: {
        assignment_id: '60a1601e0a1684d7a07aa965', // test_assignment_id: 60acbd27f1b9b01afffaf7ba, BLG: 60a1601e0a1684d7a07aa965
      },
    });
  },
  async temp_stats() {
    const result = await axios.post(`${helpers.apihost()}temp_stats`, {
      params: {
        assignment_id: '60a1601e0a1684d7a07aa965', // test_assignment_id: 60acbd27f1b9b01afffaf7ba
      },
    });
    console.log(result);
  },
  async load(context, p) {
    const result = await axios.get(`${helpers.apihost()}assignment/${p}`);
    context.commit('set_assignment', result.data[0]);
  },
  async load_courses(context, assignmentId) {
    const result = await axios.get(`${helpers.apihost()}load_courses/${assignmentId}`);
    context.commit('set_stats', result.data);
  },
  async save(context) {
    const result = await axios.put(`${helpers.apihost()}assignment`, state.a, {
      headers: {
        'Content-type': 'application/json',
      },
    });
    context.commit('set_id', result.data.id);
    if (result) {
      return true;
    }
    return false;
  },
  async start_assignment({ dispatch }) {
    state.a.status = 'running';
    await dispatch('save');
    await axios.get(`${helpers.apihost()}start_assignment/${state.a.id}`);
  },
  async add_assignment({ dispatch }) {
    state.a.status = 'assigned';
    await dispatch('save');
    await axios.get(`${helpers.apihost()}add_assignment/${state.a.id}`);
  },
  async edit_reminder(context, payload) {
    const result = await axios.post(`${helpers.apihost()}edit_reminder_assignment`, {
      params: {
        settings: {
          inc_sat: state.a.inc_sat,
          inc_sun: state.a.inc_sun,
          frequency: state.a.frequency,
          canchange: state.a.canchange,
          canrate: state.a.canrate,
          canstop: state.a.canstop,
          maxdays: state.a.maxdays,
        },
        assignmentId: state.a.id,
        editType: payload,
      },
    });
    if (result) {
      return true;
    }
    return false;
  },
  async stop_assignment() {
    state.a.status = 'stopped';
    await axios.get(`${helpers.apihost()}assignment/stop_assignment/${state.a.id}`);
  },
  async delete_assignment() {
    console.log('deleting');
    const result = await axios.get(`${helpers.apihost()}delete_assignment/${state.a.id}`);
    if (result) {
      state.a.status = 'deleted';
      return true;
    }
    return false;
  },
  async end_assignment() {
    const result = await axios.get(`${helpers.apihost()}end_assignment/${state.a.id}`);
    if (result) {
      state.a.status = 'ended';
      return true;
    }
    return false;
  },
  async add_one_assignment(context, payload) {
    const result = await axios.post(`${helpers.apihost()}add_one_assignment`, {
      params: {
        assignment_id: payload.assignmentId,
        user_id: payload.userId,
      },
    });
    if (result) {
      if (state.a.status === 'ended') {
        const result2 = await axios.get(`${helpers.apihost()}end_assignment/${state.a.id}`);
        if (result2) {
          return true;
        }
      }
      return false;
    }
    return false;
  },
  async print_stats(context, payload) {
    const result = await axios.post(`${helpers.apihost()}print_stats`, payload, { responseType: 'arraybuffer' });
    if (result) {
      console.log(result);
      const blob = new Blob([result.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = payload.assignment_name;
      link.click();
      return true;
    }
    return false;
  },
  async get_csv(context, payload) {
    const result = await axios.post(`${helpers.apihost()}get_csv`, payload);
    if (result) {
      console.log(result);
      const blob = new Blob([result.data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = payload.assignment_name;
      link.click();
      return true;
    }
    return false;
  },
};

const mutations = {
  set_assignment(s, payload) {
    state.a = payload;
    state.loading = false;
  },
  set_stats(s, payload) {
    state.stats = payload;
  },
  set_id(s, v) {
    state.a.id = v;
  },
  set_loading(s, v) {
    state.loading = v;
  },
  new_assignment(s, payload) {
    const u = this.getters['User/user'];
    const obj = {
      name: '',
      desc: '',
      status: 'new',
      course_id: '',
      group_id: payload,
      required_assignment: null,
      earliest_start: '',
      logo_l: '',
      logo_r: '',
      sendername: u.fullName,
      senderemail: u.u,
      firstmail: '',
      followmail: '',
      anchormail: '',
      lastmail: '',
      attention: 0,
      inc_sat: false,
      inc_sun: false,
      frequency: '',
      canchange: true,
      canstop: false,
      canrate: false,
      maxdays: 180,
    };
    state.a = obj;
    state.loading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
