<template>
  <section>
    <Navbar />
    <section class="profile">
      <section class="hero is-small">
        <div class="hero-body">
          <h1 class="admin-title">{{ $t('profile.title-thin') }} </h1>
          <h1 class="admin-title fat-text">{{ $t('profile.title-bold') }}</h1>
          <div class="pt-5">
            <div class="spacer">
              <h3>{{ $t('profile.attention') }}</h3>
              <h2 v-if="!editAttention" class="inline">{{ user.attention }}</h2>
              <input v-else v-model="user.attention" type="text"> <!---
              <button class="inline button is-small is-light" @click="toggleAttention()">
                <font-awesome-icon v-if="editAttention" class="icon" :icon="['fa', 'check']" />
                <font-awesome-icon v-if="!editAttention" class="icon" :icon="['fa', 'pen']" />
              </button> -->
            </div>
            <div class="spacer">
              <h3>{{ $t('profile.name') }}</h3>
              <h2>{{ user.firstname }}</h2>
            </div>
            <div class="spacer">
              <h3>{{ $t('profile.surname') }}</h3>
              <h2>{{ user.lastname }}</h2>
            </div>
            <div class="spacer">
              <h3>{{ $t('profile.mail') }}</h3>
              <h2>{{ user.u }}</h2>
            </div>
            <div class="spacer">
              <Button :text="$t('profile.change-password')" :type="'zoom'"
              @click="forgot_pw()" />
            </div>
          </div>
        </div>
      </section>
    </section>
    <Footer />
  </section>
</template>

<script>
import Button from '@/traincom/components/Common/Button.vue';
import Navbar from '../../components/Common/Navbar.vue';
import Footer from '../../components/Common/Footer.vue';

export default {
  components: {
    Navbar,
    Footer,
    Button,
  },

  data() {
    return {
      editAttention: false,
    };
  },

  computed: {
    user() {
      return this.$store.getters['User/user'];
    },
    client() {
      return this.$store.getters['User/client'];
    },
  },

  methods: {
    toggleAttention() {
      this.editAttention = !this.editAttention;
    },
    forgot_pw() {
      this.$buefy.dialog.confirm({
        title: this.$t('profile.change-password'),
        message: this.$t('profile.forgot-password-text'),
        confirmText: this.$t('profile.send-mail'),
        cancelText: this.$t('common.cancel'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('User/forgot_pw', this.user.u);
        },
      });
    },
  },
};
</script>

<style lang="scss" theme="a">
.profile {
  font-family: "Noto Sans";
  margin-top: 3.899rem;
  .notification {
    padding-left: 6rem;
    padding-top: 3rem;
    @media only screen and (max-width: 900px) {
      padding-left: .5rem;
    }
  }

  .spacer {
    padding-bottom: 2rem;
  }

  .inline {
    display: inline-flex;
  }

  button {
    margin-left: 1rem;
  }

  h3 {
    font-size: 1.1rem;
    font-weight: 600;
  }
  h2 {
    font-size: 1.3rem;
  }
  h1 {
    padding: 0rem !important;
  }
  input {
    font-size: 1.3rem;
    border-style: line;
    border-width: 1px;
    background: none;
  }
  Button {
    margin-left: 0;
  }

  .admin-title {
    display: inline;
    text-transform: uppercase;
    font-size: 3em;
    padding-left: 1rem;
    padding-top: 2rem;
  }

  .fat-text {
    font-family: "Noto Sans";
    font-weight: 900;
  }

  .background-color-client {
    background-color: var(--secondary-color);
    color: var(--secondary-color-text);
  }

  .hero-body {
    padding-left: 7rem !important;
    @media only screen and (max-width: 900px) {
      padding-left: 3rem !important;
    }
  }
}
</style>

<style lang="scss" theme="b">
.profile {
  margin-top: 3.899rem;
  .notification {
    padding-left: 6rem;
    padding-top: 3rem;
    @media only screen and (max-width: 900px) {
      padding-left: .5rem;
    }
  }

  .spacer {
    padding-bottom: 2rem;
  }

  .inline {
    display: inline-flex;
  }

  button {
    margin-left: 1rem;
  }

  h3 {
    font-size: 1.1rem;
    font-weight: 600;
  }
  h2 {
    font-size: 1.3rem;
  }
  h1 {
    padding: 0rem !important;
  }
  input {
    font-size: 1.3rem;
    border-style: line;
    border-width: 1px;
    background: none;
  }
  Button {
    margin-left: 0;
  }

  .admin-title {
    display: inline;
    text-transform: uppercase;
    font-size: 3em;
    padding-left: 1rem;
    padding-top: 2rem;
  }

  .fat-text {
    font-weight: 900;
  }

  .background-color-client {
    background-color: var(--secondary-color);
    color: var(--secondary-color-text);
  }

  .hero-body {
    padding-left: 7rem !important;
    @media only screen and (max-width: 900px) {
      padding-left: 3rem !important;
    }
  }
}
</style>
