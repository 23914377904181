<template>
  <section class="modul-intro fixed-nav" :class="gobran">
    <div class="columns is-desktop responsive-pad" aria-controls="collapsable">
      <div class="column intro-text is-5-desktop is-6-widescreen">
        <h3><b>{{ $t('common.hello') }} {{ user.attention }} {{ user.fullName }},</b></h3>
        <h3 v-html="user.client_id.dashboard[locale].dbtext"></h3>
      </div>
      <div class="column is-7-desktop is-6-widescreen video">
        <span v-if="has_video">
          <video :src="user.client_id.dashboard[locale].videolink"
          :poster="user.client_id.dashboard[locale].videoposter" controls>!----</video>
        </span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: String,
    isCollapsable: Boolean,
  },
  data() {
    return {
      isOpen: true,
    };
  },
  computed: {
    has_video() {
      return (this.user.client_id.dashboard[this.locale].videolink.length > 0);
    },
    locale() {
      if (this.user.client_id.dashboard[this.$i18n.locale]) {
        return this.$i18n.locale;
      }
      return 'en-US';
    },
    user() {
      return this.$store.getters['User/user'];
    },
    client() {
      return this.$store.getters['User/client'];
    },
    gobran() {
      if (this.user.client_id.name === 'GOBRAN') {
        return 'background-image';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" theme="a">
.background-image {
  background-image: url('/images/focus-impact-hero.svg');
  background-color: #F6F7F8;
  background-position: right bottom;
  background-repeat: no-repeat;
}
.modul-intro {

  h1 {
    font-family: "Noto Sans";
    color: black;
    display: inline;
  }

  h3 {
    font-family: "Noto Sans";
    padding-bottom: 1rem;
  }

  .columns {
    padding-top: 6rem;
    @media only screen and (max-width: 1216px){
      padding-top: 3.5rem;
    }
  }

  .intro-text {
    color: black;
    padding-right: 4.25rem;
    @media only screen and (max-width: 1216px){
      padding-right: 1.5rem;
    }
  }

  .video {
    padding-bottom: 3rem;
    filter: drop-shadow(0px 32px 46px rgba(0, 0, 0, 0.25));
  }

  span.icon {
    margin-left: 1rem;
    &:hover {
      cursor: pointer;
    }
  }

  .panel {
    box-shadow: none;
  }

  .contact {
    padding-bottom: 3rem;
  }

  .icon {
    transition: transform ease-out .3s;
    transform: rotate(90deg);
  }

  .rotate {
    transform: rotate(180deg);
  }
}
</style>

<style lang="scss" theme="a">
.background-image {
  background-image: url('/images/focus-impact-hero.svg');
  background-color: #F6F7F8;
  background-position: right bottom;
  background-repeat: no-repeat;
}
.modul-intro {

  h1 {
    font-family: "Noto Sans";
    color: black;
    display: inline;
  }

  h3 {
    font-family: "Noto Sans";
    padding-bottom: 1rem;
  }

  .columns {
    padding-top: 6rem;
    @media only screen and (max-width: 1216px){
      padding-top: 3.5rem;
    }
  }

  .intro-text {
    color: black;
    padding-right: 4.25rem;
    @media only screen and (max-width: 1216px){
      padding-right: 1.5rem;
    }
  }

  .video {
    padding-bottom: 3rem;
    filter: drop-shadow(0px 32px 46px rgba(0, 0, 0, 0.25));
  }

  span.icon {
    margin-left: 1rem;
    &:hover {
      cursor: pointer;
    }
  }

  .panel {
    box-shadow: none;
  }

  .contact {
    padding-bottom: 3rem;
  }

  .icon {
    transition: transform ease-out .3s;
    transform: rotate(90deg);
  }

  .rotate {
    transform: rotate(180deg);
  }
}
</style>

<style lang="scss" theme="a">
.background-image {
  background-image: url('/images/focus-impact-hero.svg');
  background-color: #F6F7F8;
  background-position: right bottom;
  background-repeat: no-repeat;
}
.modul-intro {

  h1 {
    font-family: "Noto Sans";
    color: black;
    display: inline;
  }

  h3 {
    font-family: "Noto Sans";
    padding-bottom: 1rem;
  }

  .columns {
    padding-top: 6rem;
    @media only screen and (max-width: 1216px){
      padding-top: 3.5rem;
    }
  }

  .intro-text {
    color: black;
    padding-right: 4.25rem;
    @media only screen and (max-width: 1216px){
      padding-right: 1.5rem;
    }
  }

  .video {
    padding-bottom: 3rem;
    filter: drop-shadow(0px 32px 46px rgba(0, 0, 0, 0.25));
  }

  span.icon {
    margin-left: 1rem;
    &:hover {
      cursor: pointer;
    }
  }

  .panel {
    box-shadow: none;
  }

  .contact {
    padding-bottom: 3rem;
  }

  .icon {
    transition: transform ease-out .3s;
    transform: rotate(90deg);
  }

  .rotate {
    transform: rotate(180deg);
  }
}
</style>

<style lang="scss" theme="b">
.background-image {
  background-image: url('/images/focus-impact-hero.svg');
  background-color: #F6F7F8;
  background-position: right bottom;
  background-repeat: no-repeat;
}
.modul-intro {

  h1 {
    color: black;
    display: inline;
  }

  h3 {
    padding-bottom: 1rem;
  }

  .columns {
    padding-top: 6rem;
    @media only screen and (max-width: 1216px){
      padding-top: 3.5rem;
    }
  }

  .intro-text {
    color: black;
    padding-right: 4.25rem;
    @media only screen and (max-width: 1216px){
      padding-right: 1.5rem;
    }
  }

  .video {
    padding-bottom: 3rem;
    filter: drop-shadow(0px 32px 46px rgba(0, 0, 0, 0.25));
  }

  span.icon {
    margin-left: 1rem;
    &:hover {
      cursor: pointer;
    }
  }

  .panel {
    box-shadow: none;
  }

  .contact {
    padding-bottom: 3rem;
  }

  .icon {
    transition: transform ease-out .3s;
    transform: rotate(90deg);
  }

  .rotate {
    transform: rotate(180deg);
  }
}
</style>
