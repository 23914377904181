<template>
  <section class="pdf-view">
    <div v-if="!editmode" class="pdf">
      <object :data="page.content" style="width:100%;height:400px"></object>
    </div>
    <div v-if="editmode" class="notification">
      <span v-if="editmode" @click="del_page()" class="del">
        <font-awesome-icon class="icon red" :icon="['fas', 'trash']" />
      </span>
      <object :data="page.content" style="width:100%;height: 100%;"></object>
      <div v-if="editmode">
        <input type="text" v-model="page.content" class="input link-input"
        :placeholder="$t('course.pdf-edit-placeholder')">
        <Button v-if="!editmode" @click="next()" :text="$t('common.continue')" :type="'zoom'"/>
      </div>
      <b-field v-if="editmode">
        <b-switch v-model="page.note_activated">
          {{ $t('course.deactivate-notes') }}
        </b-switch>
      </b-field>
    </div>
  </section>
</template>

<script>
import Button from '@/traincom/components/Common/Button.vue';

export default {
  props: ['page', 'editmode'],

  components: {
    Button,
  },

  methods: {
    next() {
      this.$emit('end');
    },
    del_page() {
      this.$emit('del_page');
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-view {

  .link-input {
    margin: 1rem;
  }

  .notification {
    margin-left: 1rem;
    margin-right: 1rem;
    height: 100%;
    margin-bottom: 1rem;
  }

  .icon {
    font-size: 2rem;
    margin-right: 1rem;
    transition: all .3s;
    :hover {
      cursor: pointer;
      transform: scale(105%);
      transition: all .3s;
    }
  }

  .red {
    color: red;
  }

  .del {
    float: right;
  }

  .pdf {
    padding: 1rem;
  }
}
</style>
