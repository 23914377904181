<!-- eslint-disable max-len -->
<template>
  <section>
    <LoginPage v-if="!loading" :client="this.$route.params.client" />
  </section>
</template>

<script>

import LoginPage from './HomeSubpages/LoginPage.vue';

export default {
  mounted() {
    let client = '';
    if (this.$route.params.client) client = this.$route.params.client;
    else client = 'login';
    this.$store.dispatch('Clients/load_client', client).then(() => {
      const bodyStyles = document.body.style;
      bodyStyles.setProperty('--primary-color', this.$store.getters['Clients/login_client'].pcolor);
      bodyStyles.setProperty('--secondary-color', this.$store.getters['Clients/login_client'].scolor);
      bodyStyles.setProperty('--primary-color-text', this.$store.getters['Clients/login_client'].pcolortext);
      bodyStyles.setProperty('--secondary-color-text', this.$store.getters['Clients/login_client'].scolortext);
      this.loading = false;
    });
    this.loading = false;
  },
  data() {
    return {
      loading: true,
    };
  },
  components: {
    LoginPage,
  },
  computed: {
  },
};
</script>

<style lang="scss">
</style>
