/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint-disable max-len */

import axios from 'axios';
import helpers from '@/traincom/mixins/helpers';

const state = {
};

const getters = {
};

const actions = {
  async send_prompt(context, msg) {
    const result = await axios.get(`${helpers.apihost()}send_prompt/${msg}`);
    if (result) {
      return result.data;
    }
    return false;
  },
};

const mutations = {
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
