import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=ef42eda8&scoped=true&"
import script from "./Button.vue?vue&type=script&lang=js&"
export * from "./Button.vue?vue&type=script&lang=js&"
import style0 from "./Button.vue?vue&type=style&index=0&id=ef42eda8&lang=scss&scoped=true&theme=a&"
import style1 from "./Button.vue?vue&type=style&index=1&id=ef42eda8&lang=scss&scoped=true&theme=b&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef42eda8",
  null
  
)

export default component.exports