<template>
  <div class="container container-inex is-fluid bt">
    <div class="notification">
      <h1 class="inex-title">{{ inex.title }} </h1>
      <p v-html="inex.body" class="inex-body"></p>
      <br>
      <div class="has-text-centered">
        <span v-if="is_finished">
          <Button @click="set_course_status" :text="inex.action" :type="'zoom'"/>
        </span>
        <span v-else>
          <Button :text="inex.action" :type="'zoom'" @click="$emit('close')" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../Common/Button.vue';

export default {
  components: {
    Button,
  },

  created() {
    if (this.inex.tlte) {
      this.$store.getters['Course/curr_course'].status = 12;
      this.$store.dispatch('Course/save');
    }
  },

  computed: {
    is_finished() {
      return (this.$store.getters['Course/max_unit']) + 1 > this.$store.getters['Course/units'].length;
    },
    show_button() {
      if (this.$store.getters['Course/max_unit'] + 1 <= this.$store.getters['Course/princi'].length || this.$store.getters['Course/get_course'].is_test) {
        return true;
      }
      return false;
    },
    client() {
      return this.$store.getters['User/client'];
    },
  },

  props: ['inex'],

  methods: {
    set_course_status() {
      this.$store.getters['Course/curr_course'].status = 12;
      this.$store.dispatch('Course/save');
      if (this.client.id === '6085b6010df67064d9332fb0') {
        window.location.href = 'https://trusted-employer.blg.de/';
        this.$store.commit('User/logout');
      } else {
        console.log('hä?');
        setTimeout(() => {
          this.$router.push('/dashboard');
        }, 800);
        // this.$router.push({ path: `/${this.$store.getters['User/client'].login_param}` });
      }
    },
  },
};
</script>

<style lang="scss">

.container-inex {
  padding-bottom: 0 !important;
  color: black;
  height: 100%;

  .notification {
    height: 100%;
    min-height: 20rem;
    background: white;
  }

  .bt {
    margin-top: 4rem;
  }

  .inex-title {
    font-family: "Noto Sans";
    font-size: 2rem;
    font-weight: 500;
    padding-left: 0.2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;

    @media only screen and (max-width: 600px) {
      font-size: 1.4rem;
    }
  }

  .inex-body {
    font-family: "Noto Sans";
    font-weight: 400;
    color: black;
    font-size: 1.2rem;

    @media only screen and (max-width: 600px) {
      font-size: .9rem;
      }
  }
}
</style>
