import axios from 'axios';
import content from '@/mixins/content';
import helpers from '@/traincom/mixins/helpers';

const state = {
  isOpen: 0,
  home: false,
  form: false,
  footer: false,
  content_home: {},
  content_a: null,
  content_b: null,
  content_c: null,
  content_footer: {},
  contact_form: {},
};

const getters = {
  get_open: () => state.isOpen,
  loading: () => {
    if (state.home && state.form && state.footer) {
      return true;
    }
    return false;
  },
  get_content_home: () => state.content_home,
  get_content_a: () => state.content_a,
  get_content_b: () => state.content_b,
  get_content_c: () => state.content_c,
  get_content_footer: () => state.content_footer,
  get_content_contact_form: () => state.contact_form.contact_form,
};

const actions = {
  async load_content_home(context) {
    const result = await axios.get(`${content.content_host()}homes/2`);
    if (result) {
      context.commit('set_content_home', result.data);
    }
    const resultc = await axios.get(`${content.content_host()}contact-forms/1`);
    if (resultc) {
      context.commit('set_content_contact_form', resultc.data);
    }
    const resultf = await axios.get(`${content.content_host()}footers`);
    if (resultf) {
      context.commit('set_content_footer', resultf.data[0]);
    }
  },
  async load_contact_form(context) {
    const result = await axios.get(`${content.content_host()}contact-forms/1`);
    if (result) {
      context.commit('set_content_contact_form', result.data);
    }
  },
  async load_content_a(context, slug) {
    const result = await axios.get(`${content.content_host()}typ-as?slug=${slug}`);
    if (result) {
      context.commit('set_content_typeA', result.data[0]);
    }
  },
  async load_content_b(context, slug) {
    const result = await axios.get(`${content.content_host()}typ-bs?slug=${slug}`);
    if (result) {
      context.commit('set_content_typeB', result.data[0]);
    }
  },
  async load_content_c(context, slug) {
    const result = await axios.get(`${content.content_host()}typ-cs?slug=${slug}`);
    if (result) {
      context.commit('set_content_typeC', result.data[0]);
    }
  },
  async load_content_footer(context) {
    const result = await axios.get(`${content.content_host()}footers`);
    if (result) {
      context.commit('set_content_footer', result.data[0]);
    }
  },
  async send_contact_mail(context, form) {
    const result = await axios.post(`${helpers.apihost()}send_contact_mail`, {
      params: {
        form,
      },
    });
    if (result) {
      return true;
    }
    return false;
  },
};

const mutations = {
  set_open(s, payload) {
    state.isOpen = payload;
  },
  set_content_home(s, payload) {
    state.content_home = payload;
    state.home = true;
  },
  set_content_typeA(s, payload) {
    state.content_a = payload;
  },
  set_content_typeB(s, payload) {
    state.content_b = payload;
  },
  set_content_typeC(s, payload) {
    state.content_c = payload;
  },
  set_content_footer(s, payload) {
    state.content_footer = payload;
    state.footer = true;
  },
  set_content_contact_form(s, payload) {
    state.contact_form = payload;
    state.form = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
