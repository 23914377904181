import Vue from 'vue';
import Vuex from 'vuex';
import Home from './modules/home';
import User from '../traincom/store/modules/user';
import Course from '../traincom/store/modules/course';
import Courses from '../traincom/store/modules/courses';
import Questions from '../traincom/store/modules/questions';
import userProcess from '../traincom/store/modules/userProcess';
import Clients from '../traincom/store/modules/clients';
import Groups from '../traincom/store/modules/groups';
import Uploads from '../traincom/store/modules/uploads';
import Assignment from '../traincom/store/modules/assignment';
import Account from '../traincom/store/modules/account';
import Aigo from '../traincom/store/modules/aigo';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Home,
    User,
    Course,
    Courses,
    Questions,
    userProcess,
    Clients,
    Groups,
    Uploads,
    Assignment,
    Account,
    Aigo,
  },
});
