<template>
  <div class="container is-fluid bt">
    <div v-if="!editmode" class="notification">
      <h3>
        <h3 class="counter">
          {{ this.curr_question + 1 }}
        </h3>
        {{ question }}
      </h3>
      <h2>{{ $t('course.check.title') }}</h2>
      <div class="answer-box">
        <ul v-for="entry in options" :key="entry.id">
          <div class="answers level-left">
            <div class="field">
                <b-checkbox :key="curr_question" @input="next_timed()"
                v-model="entry.a" :disabled="!active_box">{{ entry.o }}</b-checkbox>
            </div>
          </div>
        </ul>
      </div>
      <b-progress class="progress-bar" :value="tPercent()" size="is-medium" show-value>
        <span class="black">{{ this.curr_question + 1 }} {{ $t('course.check.out-of-40') }}</span>
      </b-progress>
      <nav class="level is-mobile">
        <div class="level-item has-text-left">
          <div>
            <Button :text="$t('common.back')" @click="back()" :type="'zoom'"
            :disabled="went_back"/>
          </div>
        </div>
        <div class="level-item has-text-right">
        </div>
      </nav>
    </div>
    <div v-else class="notification check-edit">
      <span v-if="editmode" @click="del_page()" class="del">
        <font-awesome-icon class="icon del-page red" :icon="['fas', 'trash']" />
      </span>
      <h1>Mindset-Check</h1>
    </div>
  </div>
</template>

<script>
import Button from '@/traincom/components/Common/Button.vue';

export default {
  components: {
    Button,
  },

  props: ['editmode'],

  data() {
    return {
      curr_question: 0,
      active_box: true,
      clicked_back: false,
      options: [
        {
          o: this.$t('course.check.a-0'),
          a: false,
        },
        {
          o: this.$t('course.check.a-1'),
          a: false,
        },
        {
          o: this.$t('course.check.a-2'),
          a: false,
        },
        {
          o: this.$t('course.check.a-3'),
          a: false,
        },
      ],
    };
  },

  computed: {
    question() {
      return this.$store.getters['Questions/question'](this.curr_question);
    },
    went_back() {
      if (this.clicked_back || this.curr_question === 0) {
        return true;
      }
      return false;
    },
  },

  methods: {
    next_timed() {
      this.active_box = false;
      let currAnswer;
      if (this.options[0].a) {
        currAnswer = 1;
      } else if (this.options[1].a) {
        currAnswer = 2;
      } else if (this.options[2].a) {
        currAnswer = 3;
      } else if (this.options[3].a) {
        currAnswer = 4;
      }
      setTimeout(() => { this.next(); this.clicked_back = false; }, 1000);
      this.$store.commit('Questions/add_answer', currAnswer, this.curr_question);
    },

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    next() {
      this.skipped_one = false;
      for (let i = 0; i < 4; i += 1) {
        this.options[i].a = false;
      }
      this.active_box = true;
      if (this.curr_question < 39) {
        this.curr_question += 1;
      } else {
        this.save();
        this.$emit('end');
      }
    },

    back() {
      this.curr_question -= 1;
      this.clicked_back = true;
      this.$store.commit('Questions/del_last_answer');
    },

    tPercent() {
      return 2.5 * this.curr_question;
    },

    save() {
      this.$store.dispatch('Questions/save');
    },

    del_page() {
      this.$emit('del_page');
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  height: 100%;
}

.check-edit {
  margin: 1rem;
}

.black {
  color: black;
;
}

.bt {
  color: black;
  height: 100%;
}

.counter {
  font-family: "Roboto";
  font-weight: 500;
  color: black;
  font-size: 4rem;
  padding-bottom: 0;
  padding-right: 1rem;
  margin-top: -1rem;
  min-width: 6rem;
  @media only screen and (max-width: 600px) {
    font-size: 3.6rem;
  }
}

.del {
  float: right;
}

h1 {
  font-family: "Roboto";
  font-weight: 500;
  color: black;
  font-size: 2.2rem;
}

h2 {
  font-family: "Roboto";
  font-weight: 400;
  color: black;
  font-size: 1.2rem;
  padding-bottom: 1rem;
  @media only screen and (max-width: 600px) {
    font-size: 1rem;
  }
}

h3 {
  font-family: "Roboto";
  font-weight: 450;
  color: black;
  font-size: 1.4rem;
  padding-bottom: 1rem;
  min-height: 7rem;
  display: inline-flex;
  @media only screen and (max-width: 600px) {
        font-size: 1.2rem;
  }
}

.icon {
  transition: all .3s;
  :hover {
    cursor: pointer;
    transform: scale(105%);
    transition: all .3s;
  }
}

.answers {
  font-family: "Roboto";
  font-weight: 450;
  color: black;
  font-size: 1.2rem;
  @media only screen and (max-width: 600px) {
      font-size: 1rem;
    }
}

.answers {
  line-height: 2rem;
}

.answer-box {
  padding-bottom: 1rem;
}

.progress-bar {
  margin: 0 !important;
}

.red {
    color: red;
  }

.del-page {
  margin: 0;
  font-size: 2rem;
}

//animations

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

</style>
