<template>
  <button class="btn button" :class="this.type"
  @click="clicked()">
    <span v-if="this.type === 'hasIcon'"  class="icon mobileIcon">
      <font-awesome-icon :icon="icon" />
    </span>
    <span v-if="this.type === 'hasIcon'" class="mobileText">
      {{this.text}}
    </span>
    <span v-if="this.type !== 'hasIcon'">
      {{this.text}}
    </span>
  </button>
</template>

<script>
export default {
  props: {
    text: String,
    icon: Array,
    type: String,
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters['User/is_loggedin'];
    },
  },
  methods: {
    clicked() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped theme="a">
.button {
  background-color: var(--secondary-color) !important;
  color: var(--secondary-color-text) !important;
  outline: none;
  font-weight: 600;
  font-size: 1rem;
  margin: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 40px;
  text-align: center;
  border-radius:5px;
  letter-spacing:1px;
  position: relative;
  text-shadow:0;
  margin: .2rem !important;
  letter-spacing:1px;
  cursor:
    pointer;
    transition: all 0.25s ease;
}

span {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.button:hover {
  border: 1px solid var(--primary-color) !important;
}

.on-dark {
  border: 1px solid white;
  color: white !important;
}

.on-dark:hover {
  border: 1px solid var(--primary-color) !important;
}

.mobileText {
  @media only screen and (max-width: 600px) {
    display: none;
    padding-right: 0rem;
    padding-left: 0rem;
  }
}

.mobileIcon {
  @media only screen and (max-width: 600px) {
    padding-right: 0rem;
    padding-left: 0rem;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
}
</style>

<style lang="scss" scoped theme="b">
.button {
  background-color: transparent !important;
  color: white !important;
  outline: none;
  border: 1px solid var(--primary-color);
  font-weight: 600;
  font-size: 1rem;
  margin: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 40px;
  text-align: center;
  border-radius:5px;
  letter-spacing:1px;
  position: relative;
  text-shadow:0;
  margin: .2rem !important;
  letter-spacing:1px;
  cursor:
    pointer;
    transition: all 0.25s ease;
}

.button:hover {
  border: 1px solid var(--primary-color) !important;
  letter-spacing:1px;
}

.on-dark {
  border: 1px solid white;
  color: white !important;
  background-color: transparent !important;
}

.mobileText {
  @media only screen and (max-width: 600px) {
    display: none;
    padding-right: 0rem;
    padding-left: 0rem;
  }
}

.mobileIcon {
  @media only screen and (max-width: 600px) {
    padding-right: 0rem;
    padding-left: 0rem;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
}
</style>
