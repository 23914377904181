/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import axios from 'axios';
import helpers from '@/traincom/mixins/helpers';

const state = {
  answers: [],
  titles: [
    'Das System lernt – immer!',
    'Spielregeln setzen, Spielregeln durchsetzen',
    'Führung braucht Klarheit',
    'Rahmengeber bleiben – immer!',
    'Favoriten stören Teams',
    'Konflikte managen – nicht lösen',
    'Kreativität braucht Druck',
    'Ehrlich einbeziehen statt falsch spielen',
    'Führungskräfte fragen, Fachkräfte antworten',
    'Harmoniebedürftigkeit bringt Disharmonie',
  ],
  //  1-1, 2-1, 3-1, ...
  //  1-2, 2-2, 3-2, ...
  questions: [
    'Bei mir ist das Gesagte immer auch das Gemeinte.',
    'Ich kommuniziere meine persönlichen Spielregeln zur Zusammenarbeit klar an mein Team.',
    'Ich kommuniziere klar, was ich von Mitarbeitern erwarte.',
    'Ich achte darauf, dass mein Team die Dynamik und Energie hat, die es für die aktuelle Situation braucht.',
    'Ich verzichte auf Einzellob oder Einzelkritik in der Öffentlichkeit.',
    'Ich sehe mich nicht als Löser, sondern als Manager bei Konflikten in meinem Team.',
    'Ich verstehe mich als Förderer der Kreativität meines Teams.',
    'Ich kommuniziere (von mir) getroffene Entscheidungen immer klar.',
    'Ich verstehe meine Mitarbeiter als Fachexperten (und nicht mich selbst) und führe deshalb bewusst über Fragen.',
    'Ich gebe meinem Team stets ein gemeinsames Ziel vor.',
    'Ich achte ständig darauf, was mein Team aus meinen Aktionen ableiten könnte.',
    'Ich setze meine Spielregeln konsequent im Team durch.',
    'Ich vermeide das sogenannte „Gift der Nebensätze“ in der Kommunikation mit meinem Team.',
    'Ich sehe mich selbst als Rahmengeber meines Teams – und bleibe konsequent in dieser Rolle.',
    'Meine Spielregeln zur Zusammenarbeit gelten für das gesamte Team - ohne Ausnahme oder Favoriten',
    'Ich entwickle mein Team gezielt dahin, persönliche Konflikte untereinander selbst klären zu können.',
    'Ich habe nicht den Anspruch, (immer) selbst die besten Ideen haben zu müssen.',
    'Ich beziehe mein Team nur dann in die Entscheidungsfindung ein, wenn es auch ehrlich möglich ist.',
    'Ich versuche konsequent, meine Mitarbeiter zu eigener Lösungsorientierung zu entwickeln.',
    'Ich sanktioniere Verstöße gegen meine Spielregeln konsequent.',
    'Mir ist bewusst, dass ich als Führungskraft manchmal auch „Schallplatte mit Sprung“ sein muss.',
    'Ich habe keine Angst davor, penetrant zu sein und meine Spielregeln zu wiederholen.',
    'Ich sorge dafür, dass mein Team weiß, worauf ich in der Zusammenarbeit besonders Wert lege.',
    'Ich fordere die von mir in meinem Team gewünschte Dynamik durch Fragen ein.',
    'Mir ist die negative Wirkung von Favoritentum bewusst und ich orientiere mich daran.',
    'Latente oder schwelende Konflikte, die keine Ergebnisrelevanz haben, spreche ich nicht an.',
    'Ich halte ein mögliches Schweigen bei der Ideenfindung mit meinem Team bewusst aus.',
    'Ich achte darauf, dass ich getroffene Entscheidungen gegenüber meinem Team gut erläutere und den Nutzen der Entscheidung erkläre.',
    'Ich vermeide es bewusst, meinen kompetenten Mitarbeitern die Lösungswege detailliert vorzugeben.',
    'Ich bin im Zweifel bereit, auch unangenehme Entscheidungen zu treffen (die nicht jedem gefallen).',
    'Ich achte bewusst darauf, dass ich immer berechenbar bin.',
    'Ich gestehe meinem Team hin und wieder auch begründete Ausnahmen in Bezug auf meine Spielregeln zu.',
    'Mir ist Klartext in der Kommunikation wichtiger als Rhetorik.',
    'Ich bin bereit, in meinem Team meine Spielregeln zu setzen und im Zweifel zu sanktionieren.',
    'Ich bin respektvoll im Umgang mit Mitarbeitern, aber konsequent im Vier-Augen-Gespräch.',
    'Ich sehe Konflikte als normalen Teil des Führungsalltags und scheue sie nicht.',
    'Bei mir ist eine Deadline immer verbindlich und darf nicht gerissen werden.',
    'Ich investiere bewusst in die Identifikation mit Entscheidungen (ich sorge also dafür, dass meine Mitarbeiter hinter den von mir getroffene Entscheidungen stehen).',
    'Ich frage primär nach Lösungen (in die Zukunft) und nicht nach Problemen (in die Vergangenheit).',
    'Ich sorge für eine gute Streitkultur in meinem Team (Leidenschaft beim Arbeiten ist mir wichtiger als Harmonie im Team).',
  ],
};

const getters = {
  question: () => (i) => state.questions[i],
};

const actions = {
  async save() {
    const result = await axios.post(`${helpers.apihost()}check`,
      {
        answers: state.answers,
        id: this.getters['User/currid'],
      });
    console.log(result);
  },
};

const mutations = {
  add_answer(state, answer) {
    state.answers.push(answer);
  },
  del_last_answer() {
    state.answers.pop();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
