<template>
  <section class="container question-section">
    <div :class="edit_class">
      <span v-if="editmode" @click="del_page()" class="del">
        <font-awesome-icon class="icon del-page red" :icon="['fas', 'trash']" />
      </span>
      <h4 v-if="!editmode">
        <span v-if="!tlte">{{ $t('course.question') }}</span>
        <span v-else>{{ $t('course.questions.expression') }}</span>
        {{curr_q_nr}} {{ $t('course.out-of') }} {{page_length}}
        <span v-if="!tlte"> {{ $t('course.reflection') }}</span>
      </h4>
      <h3 v-if="!editmode" v-html="page.question.q"></h3>
      <h4 v-if="!editmode" class="subtext" v-html="page.subtext"></h4>
      <input v-if="editmode" class="input" v-model="page.question.q"
      :placeholder="$t('course.questions.question-placeholder')">
      <input v-if="editmode" class="input" v-model="page.subtext"
      :placeholder="$t('course.questions.secondary-placeholder')">
      <b-select class="select" v-if="editmode" v-on:input="subtype(page.subtype)"
      :placeholder="$t('course.questions.question-type')" v-model="page.subtype">
        <option v-for="subtype in subtypes" :value="subtype" :key="subtype.id">
          {{ subtype }}
        </option>
      </b-select>
      <h4 v-if="editmode && page.subtype === 'sc'">
        {{ $t('course.questions.sc-edit') }}
      </h4>
      <h4 v-if="editmode && page.subtype === 'mc'">
        {{ $t('course.questions.mc-edit') }}
      </h4>
      <h2 v-if="page.subtype == 'sc' && curr_course_type !== 'english' && !editmode">
        {{ $t('course.questions.sc-answer') }}
      </h2>
      <h2 v-if="page.subtype == 'mc' && curr_course_type !== 'english' && !editmode" >
        {{ $t('course.questions.mc-answer') }}
      </h2>
      <h2 v-if="page.subtype == 'mc_x' && curr_course_type !== 'english' && !editmode" >
        {{ $t('course.questions.mcx-answer') }}
      </h2>
      <h2 v-if="page.subtype == 'sc_x' && curr_course_type !== 'english' && !editmode" >
        {{ $t('course.questions.scx-answer') }}
      </h2>
      <h2 v-if="curr_course_type === 'english' && !editmode" >
        Tick a box to answer!
      </h2>
      <div class="answer-box">
        <ul v-for="(answer, index) in page.question.options" :key="answer.id">
          <div class="answers level-left">
            <div class="field">
              <b-checkbox v-if="curr_course_type !== 'english' && !editmode"
              @click.native.prevent="check(index)" class="inline"
              v-model="answer.u" :class="color(answer)"
              :disabled="!active_box">
              {{ answer.o }}
              </b-checkbox>
              <b-checkbox v-if="curr_course_type === 'english' && !editmode"
              v-model="answer.u"
              @input="checked = true">
              {{ answer.o }}
              </b-checkbox>
              <div class="inline">
                <b-checkbox v-if="editmode"
                v-model="answer.a" :disabled="disabled">
                {{ answer.o }}
                </b-checkbox>
                <span v-if="editmode" @click="del_option(index)">
                  <font-awesome-icon class="icon red" :icon="['fas', 'trash']" />
                </span>
              </div>
              <img class="inline" width="72px" v-if="answer.img" :src="answer.img">
            </div>
          </div>
        </ul>
        <span v-if="editmode">
          <input class="input" @keyup.enter="add_option" v-model="edit_input"
          :placeholder="$t('course.questions.add-answer')">
        </span>
      </div>
      <div class="button-box" v-if="!editmode">
        <nav class="level is-mobile nav">
          <div class="level-item">
            <div class="">
              <Button :text="$t('common.back')" :type="'zoom'"
              :disabled="went_back" @click="back()" />
            </div>
          </div>
          <div class="level-item">
            <div>
              <Button v-if="page.min_answers == 2" :text="$t('common.continue')" :type="'zoom'"
              :disabled="!is_min_two" @click="finish()" />
              <Button v-if="page.min_answers == 1" :text="$t('common.continue')" :type="'zoom'"
              :disabled="!is_min_one" @click="finish()" />
              <Button v-if="this.page.subtype !== 'sc' && this.page.subtype !== 'sc_x'"
              :text="$t('common.continue')" :type="'zoom'"
              :disabled="!is_min_one" @click="finish()" />
            </div>
          </div>
        </nav>
      </div>
      <b-field v-if="editmode">
        <b-switch v-model="page.note_activated">
          {{ $t('course.deactivate-notes') }}
        </b-switch>
      </b-field>
    </div>
  </section>
</template>

<script>
import Button from '@/traincom/components/Common/Button.vue';

export default {
  props: ['page', 'editmode'],
  components: {
    Button,
  },

  data() {
    return {
      processing: false,
      active_box: true,
      checked: false,
      empty_answer: true,
      clicked: false,
      edit_input: '',
      subtypes: ['sc', 'mc', 'sc_x', 'mc_x'],
      disabled: false,
    };
  },

  computed: {
    client() {
      return this.$store.getters['User/client'];
    },

    type() {
      return this.$store.getters['Course/type'];
    },

    tlte() {
      return (this.type === 'tl' || this.type === 'te');
    },

    went_back() {
      return this.$store.getters['Course/went_back'];
    },

    edit_class() {
      if (this.editmode) return 'edit-background notification';
      return 'notification';
    },

    curr_q_nr() {
      return this.$store.getters['Course/curr_q_nr'];
    },

    curr_course_type() {
      return this.$store.getters['Course/curr_course_type'];
    },

    page_length() {
      return this.$store.getters['Course/get_q_count'];
    },

    is_min_one() {
      if (this.clicked === true) { return false; }
      let ticked = 0;
      for (let i = 0; i < this.page.question.options.length; i += 1) {
        if (this.page.question.options[i].u) {
          ticked += 1;
          if (ticked > 0) {
            return true;
          }
        }
      }
      return false;
    },

    is_min_two() {
      if (this.clicked === true) { return false; }
      let ticked = 0;
      for (let i = 0; i < this.page.question.options.length; i += 1) {
        if (this.page.question.options[i].u) {
          ticked += 1;
          if (ticked > 1) {
            return true;
          }
        }
      }
      return false;
    },
  },

  methods: {
    check(index) {
      if (this.page.subtype === 'mc' || this.page.subtype === 'mc_x') {
        this.page.question.options[index].u = !this.page.question.options[index].u;
        this.empty_answer = false;
        return;
      }
      this.active_box = false;
      for (let i = 0; i < this.page.question.options.length; i += 1) {
        this.page.question.options[i].u = false;
      }
      this.page.question.options[index].u = true;
      if (!this.processing) {
        this.processing = true;
        this.finish();
      }
    },

    back() {
      for (let i = 0; i < this.page.question.options.length; i += 1) {
        this.page.question.options[i].u = false;
      }
      setTimeout(() => {
        this.$emit('back');
      }, 1000);
    },

    finish() {
      this.clicked = true;
      this.checked = true;
      setTimeout(() => {
        this.processing = false;
      }, 1500);
      setTimeout(() => {
        this.$forceUpdate();
        this.active_box = true;
        this.checked = false;
        this.clicked = false;
        document.activeElement.blur();
        this.$emit('end');
      }, 1000);
    },

    color(answer) {
      if (this.checked) {
        if (this.page.subtype === 'mc' || this.page.subtype === 'sc') {
          if (answer.a) {
            return 'glow-green';
          }
          return 'glow-red';
        }
      }
      return '';
    },

    add_option() {
      const option = {
        o: this.edit_input, a: true, u: false,
      };
      this.page.question.options.push(option);
      this.edit_input = '';
    },

    del_option(index) {
      this.page.question.options.splice(index, 1);
    },

    del_page() {
      this.$emit('del_page');
    },

    subtype(type) {
      switch (type) {
        default:
          break;
        case 'sc':
          for (let i = 0; i < this.page.question.options.length; i += 1) {
            this.page.question.options[i].a = false;
          }
          this.disabled = false;
          break;
        case 'mc':
          for (let i = 0; i < this.page.question.options.length; i += 1) {
            this.page.question.options[i].a = false;
          }
          this.disabled = false;
          break;
        case 'sc_x':
          for (let i = 0; i < this.page.question.options.length; i += 1) {
            this.page.question.options[i].a = true;
          }
          this.disabled = true;
          break;
        case 'mc_x':
          for (let i = 0; i < this.page.question.options.length; i += 1) {
            this.page.question.options[i].a = true;
          }
          this.disabled = true;
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.question-section {
  height: 100%;

  h1 {
    font-family: "Noto Sans";
    font-weight: 500;
    color: black;
    font-size: 2.2rem;
  }

  h2 {
    font-family: "Noto Sans";
    font-weight: 400;
    color: black;
    font-size: 1.2rem;
    padding-bottom: 1rem;
    padding-top: 1rem;

    @media only screen and (max-width: 600px) {
      font-size: 1rem;
    }
  }

  h3 {
    font-family: "Noto Sans";
    font-weight: 450;
    color: black;
    font-size: 1.4rem;
    padding-top: 1rem;
    min-height: 6rem;
    @media only screen and (max-width: 600px) {
      font-size: 1.2rem;
    }
  }

  h4 {
    font-family: "Noto Sans";
    font-weight: 450;
    color: black;
    font-size: 1rem;
    font-style: oblique;
    padding-top: .1rem;
    padding-bottom: .1rem;
    @media only screen and (max-width: 600px) {
      font-size: .8rem;
    }
  }

  .edit-background {
    background: whitesmoke !important;
  }

  .subtext {
    font-weight: 400;
    font-size: 1.2rem;
  }

  .b-checkbox {
    margin-top: .2rem;
    display: block;
  }

  .answers {
    font-family: "Noto Sans";
    font-weight: 450;
    color: black;
    font-size: 1.2rem;
    line-height: 2rem;

    @media only screen and (max-width: 600px) {
      font-size: 1rem;
    }
    @media only screen and (max-width: 400px) {
      font-size: .8rem;
    }
  }

  .b-checkbox.checkbox:hover input[type=checkbox]:not(:disabled)+.check {
    border-color: #7a7a7a;
    color: black;
  }
  .checkbox:hover, .radio:hover {
    color: black;
  }

  .button-box {
    text-align: center;
  }

  .answer-box {
    padding-bottom: 1rem;
    overflow-wrap: break-word;
    word-break: break-all;
  }

  .glow-red {
    color: #C0564A;
    border-radius: 10px;
    text-shadow: 1px 1px 3px #C0564A;
  }

  .glow-green {
    color: #00A237;
    border-radius: 10px;
    text-shadow: 1px 1px 3px #00A237;
  }

  .notification {
    height: 100%;
    margin-bottom: 1rem;
    background: white;
    padding: 1rem !important;
  }

  // editmode

  input {
    font-family: "Noto Sans";
    font-weight: 450;
    color: black;
    font-size: 1.4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .inline {
    display: inline;
  }

  img {
    margin-left: .5rem;
    float: right;
  }

  .red {
    color: red;
  }

  .icon {
    transition: all .3s;
    :hover {
      cursor: pointer;
      transform: scale(105%);
      transition: all .3s;
    }
  }

  .del-page {
    margin: 0;
    font-size: 2rem;
  }

  .select {
    margin-bottom: 1rem;
  }

  h4 {
    margin-bottom: .5rem;
  }

  ul {
    display: flow-root;
    word-break: break-word;
  }
}

.checkbox:disabled,
.checkbox[disabled]{
  color: black !important;
  opacity: 80% !important;
}

.b-checkbox.checkbox input[type=checkbox] + .check {
  border-color: var(--primary-color);
  background: var(--primary-color);
}

.del {
  float: right;
}
</style>
