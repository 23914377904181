<template>
  <section class="custom-login">
    <div class="modal is-active">
      <div class="modal-background">
        <img :src="client.logo" class="client_logo">
      </div>
      <div class="modal-content modal-card login-card">
        <div class="container">
          <div class="titles">
            <h3 class="subtitle login-title">Login für {{client.name}} </h3>
            <p>{{client.dbline}}</p>
          </div>
          <form>
            <b-field label="Name">
              <b-input placeholder="E-Mail"
              style="max-width: 500px;"
              type="email"
              v-model="user"
              icon="email"
              autocomplete="on">
              </b-input>
            </b-field>
            <b-field label="Passwort">
              <b-input placeholder="Passwort"
              @keyup.native.enter="login()"
              style="max-width: 500px;"
              type="password"
              v-model="password"
              icon="key"
              autocomplete="on">
              </b-input>
            </b-field>
          </form>
          <div @click="toggleForgot()" class="pointer">
            <p>Passwort vergessen?</p>
          </div>
          <button class="button" disabled>Abbrechen</button>
          <button style="background-color: #c0c0c0; color: black;" class="button margin-button"
          @click="login()">Login</button>
        </div>
      </div>
    </div>
    <div class="modal is-active" v-if="forgotModal">
      <div class="modal-background">
        <img :src="client.logo" class="client_logo">
      </div>
      <div class="modal-content modal-card login-card">
        <div class="container">
          <h3 class="subtitle">Passwort vergessen</h3>
          <b-field label="E-Mail">
            <b-input placeholder="Bitte E-Mail eintragen." v-model="forgot_mail"></b-input>
          </b-field>
          <button class="button" @click="toggleForgot()">Abbrechen</button>
          <Button :text="'E-Mail senden'" :type="'zoom'" @click="forgot_pw()"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Button from '@/traincom/components/Common/Button.vue';

export default {
  components: {
    Button,
  },

  data() {
    return {
      forgotModal: false,
      user: '',
      password: '',
      forgot_mail: '',
    };
  },
  computed: {
    client() {
      return this.$store.getters['Clients/login_client'];
    },
  },
  methods: {
    login() {
      const payload = { user: this.user, password: this.password };
      this.$store.dispatch('User/login', payload).then((response) => {
        console.log('response', response.length);
        if (response) {
          this.$router.push('dashboard');
          const bodyStyles = document.body.style;
          bodyStyles.setProperty('--primary-color', this.$store.getters['User/client'].pcolor);
          bodyStyles.setProperty('--secondary-color', this.$store.getters['User/client'].scolor);
          bodyStyles.setProperty('--primary-color-text', this.$store.getters['User/client'].pcolortext);
          bodyStyles.setProperty('--secondary-color-text', this.$store.getters['User/client'].scolortext);
        } else {
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Passwort oder Nutzername falsch',
            type: 'is-danger',
          });
        }
      });
    },
    toggleForgot() {
      this.forgotModal = !this.forgotModal;
    },
    forgot_pw() {
      this.$store.dispatch('User/forgot_pw', this.forgot_mail).then((response) => {
        if (response) {
          this.$buefy.toast.open({
            message: 'E-Mail gesendet!',
            type: 'is-success',
          });
        } else {
          this.$buefy.toast.open({
            message: 'E-Mail konnte nicht geschickt werden.',
            type: 'is-danger',
          });
        }
      });
      this.forgotModal = !this.forgotModal;
    },
  },
};
</script>

<style lang="scss">

.modal-background {
  background-color: var(--secondary-color) !important;
}

.titles {
  margin-bottom: 1rem;
  .login-title {
    margin-bottom: .5rem !important;
  }
}

.client_logo {
    float: right;
    margin-right: 1rem;
    margin-top: 2.7rem;
    @media only screen and (max-width: 530px) {
      width:200px !important;
    }
    @media only screen and (max-width: 1000px) {
      width:200px;
    }
    @media only screen and (min-width: 1001px) {
      width:250px;
    }
  }

.login-card {
  color: black;
  background-color: white;
  border-radius: 15px;
  width: 21em !important;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 450;
}

p {
  font-weight: 400;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
}

.margin-button {
  margin-left: 2rem;
}

.button {
  background-color: var(--primary-color) !important;
  color: var(--primary-color-text) !important;
  font-weight: 600;
  font-size: 1rem;
  margin: 0.2rem;
}

a {
  color: black;
}

a:hover {
  color: var(--primary-color) !important;
}

.pointer:hover {
  cursor: pointer;
}
</style>
