<template>
  <header>
    <b-navbar class="custom-nav responsive-pad" fixed-top>
      <template slot="brand">
        <b-navbar-item class="no-left-pad" tag="router-link"
        :to="{ path: '/dashboard' }">
          <img v-if="isLoggedIn" :src="user.client_id.logo" class="navbar-brand" height="30">
          <img v-else src="/images/gobran_navbar.png" class="navbar-brand" height="30">
        </b-navbar-item>
      </template>
      <template slot="start">
      </template>
      <template slot="end">
        <b-navbar-item v-if="showItems" class="navbar-item navbar-anim"
        tag="router-link" :to="{ path: '/dashboard' }">
          {{ $t('navbar.dashboard') }}
        </b-navbar-item>
        <b-navbar-item v-if="showItems"
        class="navbar-item navbar-anim" :disabled="tlte"
        tag="router-link" :to="{ path: '/profile' }">
          {{ $t('navbar.profile') }}
        </b-navbar-item>
        <b-navbar-dropdown class="navbar-admin" v-if="isAdmin"  :label="$t('navbar.admin.label')">
          <b-navbar-item tag="router-link" v-if="rights.acc" :to="{ path: '/account' }">
            {{ $t('navbar.admin.account') }}
          </b-navbar-item>
          <b-navbar-item tag="router-link" v-if="rights.training && isMaster"
          :to="{ path: '/mycourses' }">
            {{ $t('navbar.admin.my-trainings') }}
          </b-navbar-item>
           <b-navbar-item tag="router-link" :to="{ path: '/user_search' }">
            {{ $t('navbar.admin.user-management') }}
          </b-navbar-item>
          <b-navbar-item tag="router-link" v-if="rights.group" :to="{ path: '/groups' }">
            {{ $t('navbar.admin.group-management') }}
          </b-navbar-item>
          <b-navbar-item tag="router-link" v-if="isMaster"
          :to="{ path: '/ex-accounts' }">
            {{ $t('navbar.admin.ex-accounts') }}
          </b-navbar-item>
        </b-navbar-dropdown>
        <b-navbar-item v-if="!isLoggedIn" tag="div">
          <div class="buttons">
            <LoginModal class="login-button" />
          </div>
        </b-navbar-item>
        <b-navbar-item v-if="isLoggedIn" tag="div" class="no-right-pad">
          <button class="round-anim" @click="logout()">
            {{ $t('navbar.logout') }}
          </button>
        </b-navbar-item>
        <!----
        <div class="locale-changer">
          <select v-model="$i18n.locale">
            <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
          </select>
        </div>
        ---->
      </template>
      <template v-if="!isLoggedIn" slot="burger">
        <b-navbar-item>
          <LoginModal class="login-button" />
        </b-navbar-item>
      </template>
    </b-navbar>
  </header>
</template>

<script>
import LoginModal from '@/traincom/components/Common/Navbar/LoginModal.vue';

export default {
  components: {
    LoginModal,
  },
  data() {
    return { langs: ['en', 'de'] };
  },
  methods: {
    logout() {
      if (this.client.id === '6085b6010df67064d9332fb0') {
        window.location.href = 'https://trusted-employer.blg.de/';
      } else {
        this.$router.push({ path: `/${this.$store.getters['User/client'].login_param}` });
      }
      return this.$store.commit('User/logout');
    },
  },
  computed: {
    user() {
      return this.$store.getters['User/user'];
    },
    client() {
      return this.$store.getters['User/client'];
    },
    rights() {
      return this.$store.getters['User/rights'];
    },
    isLoggedIn() {
      return this.$store.getters['User/is_loggedin'];
    },
    isAdmin() {
      return this.$store.getters['User/is_admin'];
    },
    isMaster() {
      return (this.user.acct_id.type === 'master');
    },
    type() {
      return this.$store.getters['Course/type'];
    },
    tlte() {
      return (this.type === 'tl' || this.type === 'te');
    },
    showItems() {
      if (this.isLoggedIn) {
        if (this.tlte) {
          if (this.editmode) {
            return true;
          }
          return false;
        }
        return true;
      }
      return false;
    },
    editmode() {
      if (this.$store.getters['Course/status'] < 10) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.custom-nav {
  font-family: "Noto Sans";
  background-color: var(--secondary-color) !important;

  header {
    color: var(--secondary-color-text) !important;
    font-weight: 400;

    .login-button {
      padding-right: 10px;
    }
  }

  .no-right-pad {
    padding-right: 0;
  }

  .no-left-pad {
    padding-left: 0;
  }

  a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover,
  a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within,
  .navbar-link:hover, .navbar-link.is-active {
    background-color: transparent;
    color: var(--secondary-color-text);
  }

  .navbar-item.has-dropdown:focus .navbar-link,
  .navbar-item.has-dropdown:hover
  .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: transparent;
  }

  .navbar-brand {
    min-height: 17.14px;
    @media only screen and (max-width: 600px) {
      margin-left: -0.2rem;
      }
  }

  .navbar-item {
    font-weight: 400;
    color: var(--secondary-color-text) !important;
    background-color: var(--secondary-color);
    transition: all .2s;
  }

  .navbar-burger {
    height: 62.39px;
  }

  .navbar-anim {
    transition: all .2s;
  }

  a {
    color: var(--secondary-color-text) !important;
    transition: all .2s;
  }

  .navbar-link:not(.is-arrowless)::after {
    border-color: white;
  }

  a:hover {
    color: var(--secondary-color-text) !important;
    background-color: var(--secondary-color) !important;
    filter: brightness(90%);
  }
  .navbar-item {
    .navbar-dropdown {
      color: var(--secondary-color-text) !important;
      background-color: var(--secondary-color) !important;
      border-top: 0px;
    }
  }

  .navbar-menu.is-active {
    background-color: var(--secondary-color);
    box-shadow: none;
  }

  .navbar-admin {
    color: var(--secondary-color-text);
    .navbar-item {
      color: var(--secondary-color-text) !important;
    }
    .label {
      transition: letter-spacing .5s;
    }
  }
}
</style>
