/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import axios from 'axios';
import helpers from '@/traincom/mixins/helpers';

const state = {
  loading: false,
  coursesData: [],
  courses: [],
};

const getters = {
  loading: () => state.loading,
  course_list: () => state.coursesData.filter((d) => d.status < 10),
  dash_list: () => state.coursesData.filter((d) => d.status >= 10 && d.status < 99),
  acc_courses: () => {
    if (state.courses.acc_courses) {
      const result = state.courses.ex_use_courses.concat(state.courses.acc_courses);
      return result;
    }
    return [];
  },
  acc_courses_ids: () => {
    const ids = [];
    if (state.courses.ex_use_courses) {
      const result = state.courses.ex_use_courses;
      for (let i = 0; i < result.length; i += 1) {
        ids.push({
          id: result[i].id,
          name: result[i].name,
          external_use: result[i].external_use,
        });
      }
      return ids;
    }
    return [];
  },
};

const actions = {
  async load(context) {
    if (state.coursesData.length === 0) state.loading = true;
    const result = await axios.get(`${helpers.apihost()}courses`);
    context.commit('set_courses', result.data);
  },
  async load_acc_courses(context) {
    if (state.courses.length === 0) state.loading = true;
    const result = await axios.get(`${helpers.apihost()}acc_courses`);
    context.commit('set_acc_courses', result.data);
  },
  async delete(s, id) {
    const result = await axios.get(`${helpers.apihost()}del_course/${id}`);
    if (result.status === 200) {
      const index = state.coursesData.findIndex((c) => c.id === id);
      state.coursesData[index].status = 99;
      return true;
    }
    console.log('The course could not be deleted. - Courses.js');
    return false;
  },
};

const mutations = {
  set_courses(s, payload) {
    state.coursesData = payload;
    state.loading = false;
  },
  set_acc_courses(s, payload) {
    state.courses = payload;
    state.loading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
