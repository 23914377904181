import axios from 'axios';
import helpers from '@/traincom/mixins/helpers';
import i18n from '@/i18n';

const state = {
  loading: false,
  is_loggedin: false,
  u: [],
};
const getters = {
  user: () => state.u,
  client: () => state.u.client_id,
  pcolor: () => state.u.client_id.pcolor,
  scolor: () => state.u.client_id.scolor,
  loading: () => state.loading,
  is_loggedin: () => state.is_loggedin,
  rights: () => state.u.rights,
  is_admin: () => {
    if (state.u === null) {
      return false;
    }
    if (state.u.rights) {
      if (state.u.rights.acc || state.u.assignment || state.u.groups || state.u.training) {
        return true;
      }
    }
    return false;
  },
  cid: () => state.u.cid,
  fullname: () => {
    const s = ' ';
    return state.u.firstname + s + state.u.lastname;
  },
  get_pdf: () => `/api/download/${state.u.id}`,
  currid: () => state.u.id,
};
const actions = {
  async login(context, payload) {
    const result = await axios.post(`${helpers.apihost()}user`, {
      params: {
        u: payload.user,
        p: payload.password,
        cid: payload.cid,
        uid: payload.uid,
      },
    });
    console.log(result.data);
    if (result.data === false) {
      return false;
    }
    context.commit('set_user', result.data[0]);
    state.is_loggedin = true;
    if (result.data[0].lang) {
      console.log(result.data[0].lang);
      i18n.locale = result.data[0].lang;
      state.lang = result.data[0].lang;
    }
    return true;
  },
  async register(context, payload) {
    const result = await axios.post(`${helpers.apihost()}new_demo_user`, payload);
    if (result.data) {
      return true;
    }
    return false;
  },
  async exportuserlinks() {
    const result = await axios.get(`${helpers.apihost()}exportuserlinks`);
    if (result) {
      return true;
    }
    return false;
  },
  async testImport() {
    const result = await axios.get(`${helpers.apihost()}testImport`);
    if (result) {
      return true;
    }
    return false;
  },
  async import_user() {
    const result = await axios.get(`${helpers.apihost()}new_user_import/${'6014170fb4d3ca409c5ce512'}`);
    if (result) {
      return true;
    }
    return false;
  },
  async set_pw(context, payload) {
    const result = await axios.post(`${helpers.apihost()}set_user_pw`, {
      params: {
        p: payload,
      },
    });
    if (result) {
      return true;
    }
    return false;
  },
  async forgot_pw(context, mail) {
    const result = await axios.get(`${helpers.apihost()}forgot_pw/${mail}`);
    if (result.data) {
      return true;
    }
    return false;
  },
};
const mutations = {
  set_user(s, payload) {
    state.u = payload;
    state.loading = false;
  },
  logout() {
    state.is_loggedin = false;
    state.u = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
