<!-- eslint-disable max-len -->

<template>
<div class="modules">
  <b-tabs position="is-left" size="is-medium"
  class="tab tab-custom is-centered responsive-pad">
  <!-- <Button class="sort-button" :text="'Datum'" :type="'hasIcon'"
    :icon="sortIcon" @click="changeSort()" /> -->
    <div v-if="loading" class="columns is-multiline is-2-desktop">
      <div v-for="i in 8" :key="i" class="column is-3-desktop is-6-tablet is-12-mobile">
        <b-skeleton size="is-small" height="320px" :active="loading" :animated="true"></b-skeleton>
      </div>
    </div>
    <b-tab-item :label="$t('dashboard.modules.overview')"
    class="courses columns is-2-desktop is-multiline">
      <div v-for="module in activeModules" :key="module.id"
      class="column is-3-desktop is-6-tablet is-12-mobile">
        <div class="course card">
          <img :src="module.img" class="column-img card-image">
          <h3 class="course-desc card-content">
            {{ module.desc }}
          </h3>
          <div class="has-text-centered card-footer">
            <router-link v-if="module.status > 10" class="card-footer-item"
            :to="{name: 'Course', params: {name: module.id}}">
              <button v-if="module.status === 12" class="start-button button">
                {{ $t('dashboard.modules.open') }}
              </button>
              <button v-else-if="module.curr_unit === 0 && module.curr_page === 0 && module.type === 'live'" class="start-button button">
                {{ $t('dashboard.modules.pre-info') }}
              </button>
              <button v-else-if="module.curr_unit === 0 && module.curr_page === 0" class="start-button button">
                {{ $t('dashboard.modules.start') }}
              </button>
              <button v-else class="start-button button">
                {{ $t('dashboard.modules.continue') }}
              </button>
            </router-link>
          </div>
          <span v-if="module.is_test" @click="del(module)">
            <button class="del-button button">
              {{ $t('dashboard.modules.delete') }}
            </button>
          </span>
        </div>
      </div>
    </b-tab-item>

    <b-tab-item :label="$t('dashboard.modules.done')" class="courses columns is-2-desktop is-multiline">
      <div v-for="module in doneModules" :key="module.id"
      class="column is-3-desktop is-6-tablet is-12-mobile">
        <div class="course card">
          <img :src="module.img" class="column-img card-image">
          <h3 class="course-desc card-content">
            {{ module.desc }}
          </h3>
          <div class="has-text-centered card-footer">
            <router-link v-if="module.status > 10" class="card-footer-item"
            :to="{name: 'Course', params: {name: module.id}}">
              <button v-if="module.status === 12" class="start-button button">
                {{ $t('dashboard.modules.open') }}
              </button>
              <button v-else-if="module.curr_unit === 0 && module.curr_page === 0" class="start-button button">
                {{ $t('dashboard.modules.start') }}
              </button>
              <button v-else class="start-button button">
                {{ $t('dashboard.modules.continue') }}
              </button>
            </router-link>
          </div>
          <span v-if="module.is_test" @click="del(module)">
            <button class="del-button button">
              {{ $t('dashboard.modules.delete') }}
            </button>
          </span>
        </div>
      </div>
    </b-tab-item>

    <b-tab-item v-if="has_trainings" :label="$t('dashboard.modules.trainings')"
    class="courses columns is-2-desktop is-multiline">
      <div v-for="module in trainings" :key="module.id"
      class="column is-3-desktop is-6-tablet is-12-mobile">
        <div class="course card">
          <img :src="module.img" class="column-img card-image">
          <h3 class="course-desc card-content">
            {{ module.desc }}
          </h3>
          <div class="has-text-centered card-footer">
            <router-link v-if="module.status > 10" class="card-footer-item"
            :to="{name: 'Course', params: {name: module.id}}">
              <button v-if="module.status === 12" class="start-button button">
                {{ $t('dashboard.modules.open') }}
              </button>
              <button v-else-if="module.curr_unit === 0 && module.curr_page === 0" class="start-button button">
                {{ $t('dashboard.modules.start') }}
              </button>
              <button v-else class="start-button button">
                {{ $t('dashboard.modules.continue') }}
              </button>
            </router-link>
          </div>
          <span v-if="module.is_test" @click="del(module)">
            <button class="del-button button">
              {{ $t('dashboard.modules.delete') }}
            </button>
          </span>
        </div>
      </div>
    </b-tab-item>

    <b-tab-item v-if="has_mindtrigger" :label="$t('common.mindtrigger')"
    class="courses columns is-2-desktop is-multiline">
      <div v-for="module in mindtrigger" :key="module.id"
      class="column is-3-desktop is-6-tablet is-12-mobile">
        <div class="course card">
          <img :src="module.img" class="column-img card-image">
          <h3 class="course-desc card-content">
            {{ module.desc }}
          </h3>
          <div class="has-text-centered card-footer">
            <router-link v-if="module.status > 10" class="card-footer-item"
            :to="{name: 'Course', params: {name: module.id}}">
              <button v-if="module.status === 12" class="start-button button">
                {{ $t('dashboard.modules.open') }}
              </button>
              <button v-else-if="module.curr_unit === 0 && module.curr_page === 0" class="start-button button">
                {{ $t('dashboard.modules.start') }}
              </button>
              <button v-else class="start-button button">
                {{ $t('dashboard.modules.continue') }}
              </button>
            </router-link>
          </div>
          <span v-if="module.is_test" @click="del(module)">
            <button class="del-button button">
              {{ $t('dashboard.modules.delete') }}
            </button>
          </span>
        </div>
      </div>
    </b-tab-item>

    <b-tab-item v-if="has_liveTrainings" :label="$t('dashboard.modules.live-trainings')"
    class="courses columns is-2-desktop is-multiline">
      <div v-for="module in liveTrainings" :key="module.id"
      class="column is-3-desktop is-6-tablet is-12-mobile">
        <div class="course card">
          <img :src="module.img" class="column-img card-image">
          <h3 class="course-desc card-content">
            {{ module.desc }}
          </h3>
          <div class="has-text-centered card-footer">
            <router-link v-if="module.status > 10" class="card-footer-item"
            :to="{name: 'Course', params: {name: module.id}}">
              <button v-if="module.status === 12" class="start-button button">
                {{ $t('dashboard.modules.open') }}
              </button>
              <button v-else-if="module.curr_unit === 0 && module.curr_page === 0" class="start-button button">
                {{ $t('dashboard.modules.pre-info') }}
              </button>
              <button v-else class="start-button button">
                {{ $t('dashboard.modules.pre-info') }}
              </button>
            </router-link>
          </div>
          <span v-if="module.is_test" @click="del(module)">
            <button class="del-button button">
              {{ $t('dashboard.modules.delete') }}
            </button>
          </span>
        </div>
      </div>
    </b-tab-item>
    <b-tab-item :label="$t('dashboard.modules.aigo')" v-if="rights_ai">
      <img :src="'/img/aigo.png'" style="height: 80px; border: none" class="mb-1">
      <div class="courses columns is-multiline">
        <div class="column is-full">
          <div class="aigo-chat">
            <span v-if="waiting"
            class="aigo-message ai-message">. . .</span>
            <span v-for="(message, i) in aigo" :key="i" :class="messageStyle(message)"
            class="aigo-message" v-html="message.text"></span>
          </div>
        </div>
        <div class="column is-full">
          <b-field label="Deine Frage" class="user-field">
              <b-input maxlength="500" rows="2" v-model="user_msg"
              :disabled="waiting" type="textarea"></b-input>
          </b-field>
          <button class="button" @click="(sendMessage())" :disabled="!canSend">
            {{ $t('dashboard.modules.send') }}
          </button>
        </div>
      </div>
    </b-tab-item>
  </b-tabs>
</div>
</template>

<script>
// import Button from '../Common/Button.vue';

export default {
  components: {//    Button,
  },
  data() {
    return {
      sort: false,
      aigo: [{ user: false, text: '<b>AIGO:</b> Hallo, was ist deine Frage?' }],
      user_msg: '',
      waiting: false,
    };
  },
  methods: {
    messageStyle(msg) {
      if (msg.user) return 'user-message';
      return 'ai-message';
    },
    changeSort() {
      this.sort = !this.sort;
    },
    sendMessage() {
      this.aigo.unshift({ user: true, text: this.user_msg });
      this.waiting = true;
      this.$store.dispatch('Aigo/send_prompt', this.user_msg).then((response) => {
        if (response) {
          this.aigo.unshift(response[1]);
          this.user_msg = '';
        } else {
          this.$buefy.toast.open({
            message: 'Error.',
            type: 'is-danger',
          });
        }
        this.waiting = false;
      });
    },
    del(c) {
      this.$buefy.dialog.confirm({
        title: 'Training löschen',
        message: `Sicher, dass Sie das Training <b>${c.name}</b> löschen wollen?`,
        confirmText: 'Löschen',
        cancelText: 'Abbrechen',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('Courses/delete', c.id).then((response) => {
            if (response) {
              this.$buefy.toast.open({
                message: 'Training gelöscht!',
                type: 'is-success',
              });
            } else {
              this.$buefy.toast.open({
                message: 'Training konnte nicht gelöscht werden.',
                type: 'is-danger',
              });
            }
          });
        },
      });
    },
  },

  created() {
    this.$store.dispatch('Courses/load');
  },

  computed: {
    loading() {
      return this.$store.getters['Courses/loading'];
    },
    canSend() {
      if (this.user_msg === '' || this.waiting) return false;
      return true;
    },
    rights_ai() {
      if (this.$store.getters['User/user'].rights) {
        if (this.$store.getters['User/user'].rights.ai) return true;
      }
      return false;
    },
    modules() {
      if (this.sort) return this.$store.getters['Courses/dash_list'];
      return this.$store.getters['Courses/dash_list'].slice();
    },
    sortIcon() {
      if (this.sort) return ['fas', 'arrow-up'];
      return ['fas', 'arrow-down'];
    },
    activeModules() {
      return this.modules.filter((modules) => (modules.status === 10
        || modules.status === 11 || modules.status === 12));
    },
    liveTrainings() {
      return this.modules.filter((modules) => modules.type === 'live');
    },
    doneModules() {
      return this.modules.filter((modules) => modules.status === 12);
    },
    trainings() {
      return this.modules.filter((modules) => modules.type === 'training');
    },
    has_trainings() {
      return (this.trainings.length > 0);
    },
    mindtrigger() {
      return this.modules.filter((modules) => modules.type === 'mindtrigger');
    },
    has_mindtrigger() {
      return (this.mindtrigger.length > 0);
    },
    has_liveTrainings() {
      return (this.liveTrainings.length > 0);
    },
  },
};
</script>

<style lang="scss" theme="a">
.modules {
  margin-top: 64px;

  .course {
    background-color: rgba(239, 239, 238, 1);
  }

  img {
    border: solid rgba(239, 239, 238, 1);
  }

  .courses {
    padding-top: 0rem;
  }

  .course-desc {
    line-height: 1.6875rem;
    font-family: "Noto Sans";
    font-size: 1rem;
    font-weight: 400;
    padding-top: 1.5rem;
    margin-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: 185px;
  }

  .card-content {
    margin: 0;
  }

  .card-footer-item {
    margin: 0 1rem !important;
  }

  .start-button {
    margin: 0rem;
    background-color: var(--secondary-color)!important;
    color: var(--secondary-color-text)!important;
    border-radius: 0;
    width: 100%;
  }
  .del-button {
    background-color: red !important;
    width: 100%;
    border-radius: 0;
    margin: 0;
  }

  .card-footer-item {
    padding: 1rem 1rem;
    margin: 0 !important;
  }

  .card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 32px 46px rgba(0, 0, 0, 0.25);
  }

  .card-footer {
    margin-top: auto;
  }

  .red {
    color: red;
    }

  .del-unit {
    font-size: 1.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    :hover {
      cursor: pointer;
    }
  }
  .sort-button {
    margin-top: -4rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 2rem !important;
    width: max-content;
  }

  .aigo-chat {
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    padding: 1rem;
    overflow: auto;
    height: 16.5rem;
    .aigo-message {
      display: block;
    }
    .user-message {
      background-color: rgb(225, 225, 225);
      width: fit-content;
      border-radius: 10px;
      padding: .25rem .5rem;
      margin-bottom: 1rem;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.404);
    }
    .ai-message {
      background-color: var(--secondary-color);
      color: white;
      width: fit-content;
      border-radius: 10px;
      padding: .25rem .5rem;
      margin-bottom: 1rem;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.404);
    }
  }
  .user-field {
    margin-bottom: 0 !important;
  }
}
</style>

<style lang="scss" theme="b">
.modules {
  margin-top: 64px;

  .course {
    background-color: rgba(239, 239, 238, 1);
  }

  img {
    border: solid rgba(239, 239, 238, 1);
  }

  .courses {
    padding-top: 0rem;
  }

  .course-desc {
    line-height: 1.6875rem;
    font-size: 1rem;
    font-weight: 400;
    padding-top: 1.5rem;
    margin-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: 185px;
  }

  .card-content {
    margin: 0;
  }

  .card-footer-item {
    margin: 0 1rem !important;
  }

  .start-button {
    margin: 0rem;
    background-color: var(--secondary-color)!important;
    border-radius: 0;
    width: 100%;
  }
  .del-button {
    background-color: red !important;
    width: 100%;
    border-radius: 0;
    margin: 0;
  }

  .card-footer-item {
    padding: 1rem 1rem;
    margin: 0 !important;
  }

  .card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 32px 46px rgba(0, 0, 0, 0.25);
  }

  .card-footer {
    margin-top: auto;
  }

  .red {
    color: red;
    }

  .del-unit {
    font-size: 1.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    :hover {
      cursor: pointer;
    }
  }
  .aigo-chat {
    background-color: gray;
    .aigo-message {
      display: block;
    }
  }
}
</style>
