<template>
  <section>
    <Navbar />
    <ModulIntro title='Willkommen zu SYSTEMISCH DENKEN in der Teamführung'
    :isOpen="true" />
    <section class="hero is-dark">
      <div class="has-text-centered">
        <router-link to="/dashboard">
          <button class="button start-button ">Training starten</button>
        </router-link>
      </div>
    </section>
    <Footer />
  </section>
</template>

<script>
import ModulIntro from '@/traincom/components/Dashboard/ModulIntro.vue';
import Navbar from '../components/Common/Navbar.vue';
import Footer from '../components/Common/Footer.vue';

export default {
  components: {
    Navbar,
    Footer,
    ModulIntro,
  },

  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.button-backg {
  height: 8.5rem;

}

.start-button {
  background-color: var(--primary-color);
  color: var(--primary-color-text);
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 1.5rem;
}

.start-button:disabled {
  background: #999;
  color: #555;
  cursor: not-allowed;
}

button {
  margin: 1em;
}
</style>
