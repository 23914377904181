<template>
  <footer class="cms">
    <div class="indent">
      <img :src="'/content' + content.logo.url" class="brand">
      <div class="columns">
        <div class="contact is-3 column">
          <br>
          <div>{{ content.contact.phone }}</div>
          <br>
          <div>{{ content.contact.mail }}</div>
          <br>
          <div v-html="content.contact.adress"></div>
        </div>
        <div class="column is-3">
          <br>
          <a class="black-link" @click="$router.push({name: 'DataProtection'})">
            <p>> Datenschutzerklärung</p></a>
          <a class="black-link" @click="$router.push({name: 'Impressum'})"><p>> Impressum</p></a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  created() {
    this.$store.dispatch('Home/load_content_footer');
  },

  computed: {
    content() {
      return this.$store.getters['Home/get_content_footer'];
    },
  },
};
</script>

<style lang="scss">
.cms {
  background-color: rgba(0, 0, 0, 0.07) !important;
  margin-top: 5rem;
  font-family: "Open Sans";
  width: 100%;
  padding: 2rem 0 !important;
  bottom: 0;
  .brand {
    max-width: 200px;
  }
  .contact {
    color: rgba(0, 0, 0, 0.516);
  }
  .columns {
    margin: 0;
  }
  .black-link {
    color: black !important;
  }
  .links {
    font-size: 1rem;
  }
  p {
    font-size: 0.9em !important;
  }
}
</style>
