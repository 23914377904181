<template>
  <section class="qc">
    <div v-if="empty && !editmode">
      <h3 class="vanishIn">
        {{ $t('course.course-tabs.quick-cards.placeholder') }}
      </h3>
    </div>
    <div v-if="!empty && !editmode">
      <h3>
        {{ $t('course.course-tabs.quick-cards.text') }}
      </h3>
    </div>
    <div class="columns is-mobile is-6 is-multiline">
      <div v-for="qcard in earnedQcards"
       :key="qcard.id" class="column has-text-centered is-full-mobile
       is-one-third-tablet is-one-quarter-desktop">
        <a :href="qcard.pdf" target="_blank">
          <img :src="qcard.img" width="300" :class="qcard.animation"
          onerror="this.onerror=null;this.src='/img/not_found.png';">
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['editmode'],
  computed: {
    earnedQcards() {
      // const cards = [];
      // for (let i = 0; i < 10; i += 1) {
      //   cards.push({
      //     img: 'https://source.unsplash.com/user/c_v_r/100x100',
      //   });
      // }
      return this.$store.getters['Course/qcards'];
    },
    empty() {
      return this.$store.getters['Course/qcards'].length === 0;
    },
  },
};
</script>

<style lang="scss">
.qc {
  margin: 0rem 3rem;
  @media only screen and (max-width: 600px) {
    margin: 0rem 1rem;
  }

  h3 {
    font-family: "Noto Sans";
    font-weight: 450;
    color: black;
    font-size: 1.4rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-height: 9rem;
    @media only screen and (max-width: 600px) {
    font-size: 1rem;
    }
  }
}

.columns {
  .tab a {
    border: none;
  }
}

.vanishIn {
  animation-name: vanishIn;
  animation-duration: 2s;
}

@-webkit-keyframes vanishIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(2, 2);
    filter: blur(90px);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
    filter: blur(0px);
  }
}

@keyframes vanishIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(2, 2);
    filter: blur(90px);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
    filter: blur(0px);
  }
}
</style>
