<template>
  <section>
    <b-upload @input="changeValue()" v-model="dropFiles" :accept="mimetype"
      :multiple="multi" drag-drop :class="custom" :type="'is-white'"
      expanded>
      <section class="section file-section">
        <div class="upload-block has-text-centered">
          <p>
            <b-icon icon="upload" size="is-medium">
            </b-icon>
          </p>
          <p>Dateien hier ablegen oder klicken</p>
        </div>
      </section>
    </b-upload>
    <div class="tags" v-if="custom !== 'qc'">
      <span v-for="(file, index) in dropFiles" :key="index" class="tag">
        {{file.name}}
        <button class="delete is-small" type="button" @click="deleteDropFile(index)">
        </button>
      </span>
    </div>
  </section>
</template>

<script>
export default {
  props: ['value', 'mimetype', 'multi', 'custom'],

  data() {
    return {
      dropFiles: [],
    };
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    changeValue() {
      this.$emit('input', this.dropFiles);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-section {
  padding-top: 0;
  padding-bottom: 0;
  color: black;
}
.upload {
  margin: 0;
}
.qc {
  background-color: white !important;
}
</style>
