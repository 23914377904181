<template>
  <section class="just-text container">
    <div class="notification">
      <span v-if="editmode" @click="del_page()" class="del">
        <font-awesome-icon class="icon red" :icon="['fas', 'trash']" />
      </span>
      <h3 v-if="!editmode">{{ page.title }}</h3>
      <input v-if="editmode" class="input" v-model="page.title"
      :placeholder="$t('course.paywall.title-edit')">
      <textarea v-if="editmode" cols="30" rows="10" class="textarea area"
      :placeholder="$t('course.paywall.text-edit')" v-model="page.text"></textarea>
      <h4 v-if="!editmode">{{ page.text }}</h4>
      <div class="has-text-centered">
        <input v-if="editmode" class="input" v-model="page.pw"
        :placeholder="$t('course.paywall.password-edit')">
        <input v-else class="input" v-model="userInput"
        :placeholder="$t('course.paywall')">
      </div>
      <div class="has-text-centered button-box">
        <nav class="level is-mobile nav">
          <div class="level-item">
            <div class="">
              <Button v-if="!editmode && !went_back" @click="back()" :text="$t('common.back')"
              :type="'zoom'"/>
            </div>
          </div>
          <div class="level-item">
            <Button v-if="!editmode && is_valid" @click="$emit('end')"
            :text="$t('common.continue')"
            :type="'zoom'"/>
          </div>
        </nav>
      </div>
    </div>
  </section>
</template>

<script>
import Button from '@/traincom/components/Common/Button.vue';

export default {
  props: ['page', 'editmode'],

  components: {
    Button,
  },
  data() {
    return {
      userInput: '',
    };
  },
  created() {
    if (this.went_back) {
      for (let i = 0; i < this.page.question.options.length; i += 1) {
        this.page.question.options[i].u = false;
      }
    }
  },

  methods: {
    back() {
      setTimeout(() => {
        this.$emit('back');
      }, 1000);
    },
    next() {
      this.$parent.next();
    },
    del_page() {
      this.$emit('del_page');
    },
  },

  computed: {
    curr_course_type() {
      return this.$store.getters['Course/curr_course_type'];
    },
    went_back() {
      return this.$store.getters['Course/went_back'];
    },
    page_length() {
      return this.$store.getters['Course/get_q_count'];
    },
    is_valid() {
      return (this.page.pw === this.userInput);
    },
  },
};
</script>

<style lang="scss" scoped>
.just-text {
  height: 100%;

  .notification {
    padding: 1rem !important;
    height: 100%;
  }

  h1 {
    font-family: "Roboto";
    font-weight: 500;
    color: black;
    font-size: 2.2rem;
  }

  h2 {
    font-family: "Roboto";
    font-weight: 400;
    color: black;
    font-size: 1.2rem;
    padding-bottom: 1rem;
  }

  h3 {
    font-family: "Roboto";
    font-weight: 450;
    color: black;
    font-size: 1.4rem;
    padding: 1rem 0;
    min-height: 6rem;

    @media only screen and (max-width: 600px) {
      font-size: 1rem;
    }
  }

  h4 {
    font-family: "Roboto";
    color: black;
    font-size: 1rem;
    padding-top: .1rem;
    padding-bottom: .1rem;
    margin-bottom: 2rem;
    @media only screen and (max-width: 600px) {
      font-size: .8rem;
    }
  }

  .answers {
    font-family: "Roboto";
    font-weight: 450;
    color: black;
    font-size: 1.2rem;
    line-height: 2rem;
  }

  .area {
    margin-top: 2rem;
  }

  .answer-box {
    padding-bottom: 1rem;
  }

  .glow-red {
    color: #C0564A;
    border-radius: 10px;
    text-shadow: 1px 1px 3px #C0564A;
  }

  .glow-green {
    color: #00A237;
    border-radius: 10px;
    text-shadow: 1px 1px 3px #00A237;
  }

  .textarea {
    padding-top: 0rem;
    margin-bottom: 1rem;
  }

  .button-box {
    margin-top: 3rem;
  }

  // editmode

  h5 {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 1rem;
    color: black;

  }

  .red {
    color: red;
  }

  .icon {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 2rem;
    transition: all .3s;
    :hover {
      cursor: pointer;
      transform: scale(105%);
      transition: all .3s;
    }
  }

  .del {
    float: right;
  }
}
</style>
