export default {
  apihost() {
    if (window.location.host === 'localhost:8080') {
      return 'http://localhost/api/';
    }
    return `https://${window.location.host}/api/`;
  },
  resource_path() {
    if (window.location.host === 'localhost:8080') {
      return 'img/qc_pdf/';
    }
    return '/qc_pdf/';
  },
};
