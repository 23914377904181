<!-- eslint-disable max-len -->
<template>
  <header>
    <LoginModal class="" /> <!---  for disabled website -> later reactivated in line 42  --->
    <b-navbar fixed-top>
      <template slot="brand">
        <b-navbar-item @click="redirect_home()">
          <img src="~@/assets/images/GOBRAN-Logo.png" class="navbar-brand" height="30">
        </b-navbar-item>
      </template>
      <template slot="start"></template>
      <template slot="end">
        <b-dropdown class="navbar-item" :triggers="['hover']" aria-role="list">
          <template #trigger>
            Leistungen
          </template>
          <b-dropdown-item aria-role="listitem" @click="jump(0, 1)">
            Moderation & Beratung</b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="jump(0, 2)">
            Training & Coaching</b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="jump(0, 3)">
            Befragungen & Reviews</b-dropdown-item>
        </b-dropdown>
        <b-navbar-item v-if="showItems"
        class="navbar-item" @click="jump(1)">
          Referenzen
        </b-navbar-item>
        <b-navbar-item v-if="showItems" @click="jump(2)"
        class="navbar-item">
          Über Uns
        </b-navbar-item>
        <b-navbar-item v-if="showItems" @click="jump(3)"
        class="navbar-item">
          Kontakt
        </b-navbar-item>
        <!--- <b-navbar-item v-if="showItems"
        class="navbar-item">
          Newsletter
        </b-navbar-item> --->
        <b-navbar-item v-if="!isLoggedIn" class="login-button" tag="div">
          <div class="buttons">
            <!--- <LoginModal class="" /> --->
          </div>
        </b-navbar-item>
        <b-navbar-item v-if="isLoggedIn" tag="div">
          <Button :text="'Logout'" :type="'zoom'" class="margin-button"
          @click="logout()" />
        </b-navbar-item>
      </template>
    </b-navbar>
  </header>
</template>

<script>
import LoginModal from '@/traincom/components/Common/Navbar/LoginModal.vue';
import Button from '@/traincom/components/Common/Button.vue';

export default {
  components: {
    LoginModal,
    Button,
  },

  data() {
    return {
      showItems: true,
    };
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters['User/is_loggedin'];
    },
    client() {
      return this.$store.getters['User/client'];
    },
  },

  methods: {
    logout() {
      if (this.client.id === '6085b6010df67064d9332fb0') {
        window.location.href = 'https://trusted-employer.blg.de/';
      }
      return this.$store.commit('User/logout');
    },
    jump(id, nr) {
      this.redirect_home();
      if (id === 0) {
        if (nr === 1) this.$store.commit('Home/set_open', 0);
        if (nr === 2) this.$store.commit('Home/set_open', 1);
        if (nr === 3) this.$store.commit('Home/set_open', 2);
      }
      setTimeout(() => this.$router.replace({ path: `/home/#${id}` }), 300); // for a nice scrolldown to id
      return '';
    },
    redirect_home() {
      this.$router.replace({ name: 'home' });
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  font-family: "Open sans";
  letter-spacing: .01px;

  .login-button {
    padding-top: 0 !important;
  }
}
.navbar {
  padding: 2rem 8rem .5rem 4rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  @media only screen and (max-width: 730px){
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .dropdown.is-active .dropdown-menu .dropdown.is-hoverable:hover .dropdown-menu {
    display: contents !important;
  }
}

.navbar-brand {
  min-height: 17.14px;
  margin-left: 1.2rem;
  @media only screen and (max-width: 600px) {
    margin-left: -0.2rem;
    }
}

.navbar-item {
  font-weight: 700;
  padding: 0.5rem 1.25rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 1) !important;
  background-color: none;
  cursor: pointer;
  :hover {
    color: rgba(0, 0, 0, 0.63) !important;
  }
}

.navbar-anim { // an alle nav-bar items
  transition: letter-spacing .5s;
  &:hover {
    letter-spacing: 1px;
    background: white;
  }
}

a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover,
a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within,
.navbar-link:hover, .navbar-link.is-active {
  color: rgba(0, 0, 0, 0.63) !important;
}

.navbar-link:not(.is-arrowless)::after {
}

.navbar-admin:hover {
  font-weight: 900;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.63);
  background: white;
  .label {
    transition: letter-spacing .5s;
    &:hover {
    letter-spacing: 1px;
    color: var(--primary-color);
    background: white;
  }
  }
}
.dropdown-item {
  text-transform: uppercase;
  font-weight: 300;
  font-family: "Open Sans";
  font-style: normal;
  letter-spacing: 0.02em;
}
</style>
