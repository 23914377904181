/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint-disable max-len */

import axios from 'axios';
import helpers from '@/traincom/mixins/helpers';

const state = {
  user_list: [],
  selected_user_data: {},
  mail_issues: [],
};

const getters = {
  get_user_list: () => state.user_list,
  get_selected_user_data: () => state.selected_user_data,
  get_mail_issues: () => state.mail_issues,
};

const actions = {
  async mail_issues(context) {
    state.selected_user_data = [];
    const result = await axios.get(`${helpers.apihost()}mail_issues`);
    if (result) {
      context.commit('set_mail_issues', result.data);
      return result;
    }
    return false;
  },
  async search_user(context, param) {
    const result = await axios.get(`${helpers.apihost()}search_user/${param}`);
    if (result) {
      context.commit('set_user_list', result.data);
      return result;
    }
    return false;
  },
  async get_user_data(context, id) {
    const result = await axios.get(`${helpers.apihost()}get_user_data/${id}`);
    console.log(result);
    if (result) {
      result.data.courses = result.data.courses.filter((course) => course.status === 11 || course.status === 10 || course.status === 12);
      context.commit('set_user_data', result.data);
      return result;
    }
    return false;
  },
  async toggle_status(context, payload) {
    console.log(payload);
    const result = await axios.post(`${helpers.apihost()}toggle_status`, {
      params: {
        id: payload.id,
        status: payload.status,
      },
    });
    if (result.request.response) {
      return true;
    }
    return false;
  },
  async set_done(context, payload) {
    console.log(payload);
    const result = await axios.post(`${helpers.apihost()}set_done`, {
      params: {
        course_id: payload,
      },
    });
    if (result.request.response) {
      return true;
    }
    return false;
  },
  async del_user(context, userId) {
    const result = await axios.post(`${helpers.apihost()}del_user`, {
      params: {
        user_id: userId,
      },
    });
    if (result) {
      return true;
    }
    return false;
  },
  async del_mail_error(context, userId) {
    const result = await axios.post(`${helpers.apihost()}del_mail_error`, {
      params: {
        user_id: userId,
      },
    });
    if (result) {
      return true;
    }
    return false;
  },
};

const mutations = {
  set_user_list(s, payload) {
    state.user_list = payload;
  },
  set_user_data(s, payload) {
    state.selected_user_data = payload;
  },
  set_mail_issues(s, payload) {
    state.mail_issues = payload;
  },
  clearSearch() {
    state.selected_user_data = [];
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
