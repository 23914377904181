<template>
  <section>
    <Navbar />
    <CourseMain />
    <CourseTabs />
    <Footer />
  </section>
</template>

<script>
/* eslint-disable no-restricted-globals */

import Navbar from '../../components/Common/Navbar.vue';
import Footer from '../../components/Common/Footer.vue';
import CourseMain from '../components/Course/CourseMain.vue';
import CourseTabs from '../components/Course/CourseTabs.vue';

export default {
  components: {
    Navbar,
    Footer,
    CourseMain,
    CourseTabs,
  },

  beforeMount() {
    if (!this.client.id === '6085b6010df67064d9332fb0') {
      window.addEventListener('beforeunload', this.preventNav);
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('beforeunload', this.preventNav);
      });
    }
  },
  /* eslint-disable no-param-reassign */
  methods: {
    preventNav(event) {
      event.preventDefault();
      event.returnValue = 'Der Kurs wird beim Neuladen der seite nicht gespeichert.';
    },
    /* eslint-enable no-param-reassign */
  },
};
</script>

<style lang="scss">
.dg-btn--ok {
  border-color: black;
  color: black;
  font-size: 1rem;
}

.dg-btn--cancel {
  background-color: var(--primary-color);
  color: var(--primary-color-text);
  font-size: 1rem;
}

.dg-content {
  font-family: "Roboto";
  color: black;
  font-weight: 400;
  font-size: 1.2rem;
}

.dg-btn-loader .dg-circle {
  background-color: var(--primary-color);
}
</style>
