<template>
  <section class="responsive-pad">
    <Button :text="$t('reminder.settings')" :type="'hasIcon'" v-if="canChange || canStop"
    :icon="['fas', 'cog']" @click="toggleModal()" />
    <div class="modal is-active" v-if="showModal">
      <div @click.prevent="toggleModal()" class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('reminder.settings') }}</p>
          <button @click.prevent="toggleModal()" class="delete" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <p>{{ $t('reminder.time-schedule') }}</p>
          <div class="control">
            <input class="input" type="number" v-model="settings.frequency" @change="control_freq"
            :disabled="!canChange || settings.frequency < 0">
          </div>
          <p>{{ $t('reminder.days') }}</p>
          <div class="field">
            <b-switch :disabled="!canChange"
            v-model="settings.inc_sat">
              {{ $t('reminder.including-saturday') }}
            </b-switch>
          </div>
          <div class="field">
            <b-switch :disabled="!canChange"
              v-model="settings.inc_sun">
              {{ $t('reminder.including-sunday') }}
            </b-switch>
          </div>
          <div class="field">
            <b-switch :disabled="!canStop"
              @input="stop($event)">
              {{ $t('common.stop') }}
            </b-switch>
          </div>
        </section>
        <footer class="modal-card-foot">
          <Button @click="toggleModal(); setReminder();" :type="'zoom'" :text="$t('common.save')" />
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
import Button from '../Common/Button.vue';

export default {
  components: {
    Button,
  },

  data() {
    return {
      showModal: false,
    };
  },

  computed: {
    settings() {
      return this.$store.getters['Course/curr_course'].settings;
    },
    canChange() {
      return this.$store.getters['Course/curr_course'].settings.canchange;
    },
    canStop() {
      return this.$store.getters['Course/curr_course'].settings.canstop;
    },
    curr_course() {
      return this.$store.getters['Course/curr_course'];
    },
    old_frequency() {
      return this.$store.getters['Assignment/get_assignment'].frequency;
    },
  },

  methods: {
    toggleModal() {
      localStorage.loggedin = true;
      this.showModal = !this.showModal;
    },
    control_freq() {
      if (this.settings.frequency < 0) this.settings.frequency = 0;
    },
    stop(value) {
      this.$store.dispatch('Assignment/load', this.curr_course.assignment_id);
      if (value === true) {
        this.$store.getters['Course/curr_course'].settings.frequency = 0;
      } else {
        this.$store.getters['Course/curr_course'].settings.frequency = this.old_frequency;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.subtitle {
  font-family: "Noto Sans";
}

.mobile-button {
  @media only screen and (max-width: 600px) {
    font-size: 0rem;
  }
}

.button {
  float: right;
}

.modal-card-title {
  font-size: 2.2rem !important;
  padding-bottom: 0;
}

.modal-card-body {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

p {
  display: inline-flex;
  padding-top: 0rem;
  padding-bottom: 0rem;
  font-size: 1.1rem;
}
.control {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: inline-flex;
}

.field {
  padding-top: 1rem;
}

button:disabled,
button[disabled]{
  background-color: var(--primary-color) !important;
  color: var(--primary-color-text);
}
</style>
