<!-- eslint-disable max-len -->

<template>
  <div class="fixed-nav">
  <section class="course-hero" :style="liveStyling">
    <div class="responsive-pad">
      <div v-if="loading" style="padding: 4rem 0">
        <b-skeleton height="40px" width="20%" :animated="true"></b-skeleton>
        <b-skeleton height="30px" width="30%" :animated="true"></b-skeleton>
        <div class="columns is-multiline is-mobile is-variable is-7">
          <div class="column topcolumn column-video container is-three-fifths-desktop
            is-12-tablet is-full-mobile">
            <b-skeleton height="500px" :animated="true"></b-skeleton>
          </div>
          <div style="margin-top: 1.8rem !important;" class="column column-right is-two-fifths-desktop is-12-tablet is-full-mobile">
            <b-skeleton height="500px" :animated="true"></b-skeleton>
          </div>
        </div>
      </div>
      <div v-if="!loading">
        <div class="modul-title" style="display: inline-block">
          <h2 class="resp-heading" v-html="curr_course.programtitle"></h2>
          <h2 class="resp-heading" v-html="curr_course.subtitle"></h2>
        </div>
        <div v-if="!live" class="columns is-multiline is-mobile is-variable is-7">
          <div class="column topcolumn column-video container is-three-fifths-desktop
          is-12-tablet is-full-mobile">

            <div v-if="curr_page_type == 'video'">
              <Cvid v-if="audio_only" v-bind:audio="true" :class="animation()" ref="p_video"
                v-on:ended="p_ended" v-bind:page="curr_page" />
              <Cvid v-else :class="animation()" ref="p_video"
                v-on:ended="p_ended" v-bind:page="curr_page" />
            </div>

            <div v-if="curr_page_type == 'check'">
              <Check :class="animation()" v-on:end="p_ended" />
            </div>

            <div v-if="curr_page_type == 'inextro'">
              <InExtro :class="animation()"
                v-on:close="p_ended" v-bind:inex="curr_page" />
            </div>

            <div v-if="curr_page_type == 'endpage'">
              <InExtro :class="animation()"
                v-on:close="u_ended" v-bind:inex="unit_end_text" />
            </div>

            <div v-if="curr_page_type == 'pquestion'">
              <Plainq :class="animation()" :key="curr_page.id" v-on:end="p_ended" v-on:back="p_back"
                v-bind:page="curr_page" />
            </div>

            <div v-if="curr_page_type == 'questions'" :key="curr_page.id">
              <Questions :class="animation()"
                :key="curr_page.id" v-on:end="p_ended" v-on:back="p_back" v-bind:page="curr_page" />
            </div>

            <div v-if="curr_page_type == 'pdf'">
              <PDF :class="animation()"
                :key="curr_page.id" v-on:end="p_ended" v-bind:page="curr_page" />
            </div>

            <div v-if="curr_page_type == 'justtext'">
              <JustText :class="animation()" v-on:back="p_back"
                :key="curr_page.id" v-on:end="p_ended" v-bind:page="curr_page" />
            </div>

            <div v-if="curr_page_type == 'pw'">
              <PayWall :class="animation()"
                :key="curr_page.id" v-on:end="p_ended" v-bind:page="curr_page" />
            </div>

            <b-field v-if="this.user.client_id.audio_feature">
              <b-switch class="pt-5" v-model="audio_only">{{ $t('course.course-main.audio') }}</b-switch>
            </b-field>
          </div>

          <div class="column column-right is-two-fifths-desktop is-12-tablet is-full-mobile"
          :style="istlte">

            <b-tabs position="is-left" size="is-medium" v-if="!tlte"
            class="tab" v-model="this.$store.getters['Course/tab_pos']">
              <b-tab-item v-if="type !== 'mindtrigger'" :label="$t('course.course-main.units')">
                <ul class="list list-content principle">
                  <li v-for="(entry, index) in princ" :key="entry.id" :id="`li${index}`"
                    v-on:click="selectChapter(entry, index)">
                    <div class="tablist is-mobile columns is-variable is-1">
                      <div class="column is-four-fifths">
                        <p v-if="entry.meta.state === 'fa fa-list'">
                          <font-awesome-icon :icon="['far', 'list-alt']"
                          class="icon-course-main"/>
                          {{ entry.meta.title2 }}
                        </p>

                        <p v-else-if="entry.meta.state === 'fa fa-lock'">
                          <font-awesome-icon :icon="['fa', 'lock']"
                          class="icon-course-main"/>
                          {{ entry.meta.title2 }}
                        </p>

                        <span v-else-if="entry.meta.state === 'far fa-circle'">
                          <font-awesome-icon :icon="['far', 'circle']"
                          class="icon-course-main"/>
                          {{ entry.meta.title2 }}
                        </span>

                        <b-tooltip v-else type="is-dark"
                          position="is-bottom" >
                          <p class="ttlcut">
                            <font-awesome-icon :icon="['far', 'check-circle']"
                            class="icon-course-main"/>
                            {{ entry.meta.title }}
                          </p>
                          <template v-slot:content>
                            <b>{{ entry.meta.title }}</b> <br> {{ entry.meta.mouse_over_text }}
                          </template>
                        </b-tooltip>
                      </div>
                      <p class="column ">{{ entry.meta.time }}</p>
                    </div>
                  </li>
                </ul>
              </b-tab-item>
              <b-tab-item :label="label_type" v-if="train_done && !no_anchors"> <!--- disabled --->
                <ul class="list list-content">
                  <li v-for="(entry, index) in anchors" :key="entry.id"
                  :id="`li${units_length - ( anchors.length - (index + 1) )}`"
                  v-on:click="selectChapter(entry, index)">
                    <div class="tablist is-mobile columns is-variable is-1">
                      <div class="column is-four-fifths">
                        <p v-if="entry.meta.state === 'fa fa-list'">
                          <font-awesome-icon :icon="['far', 'list-alt']"
                          class="icon-course-main"/>
                          {{ entry.meta.title2 }}
                        </p>

                        <p v-else-if="entry.meta.state === 'fa fa-lock'">
                          <font-awesome-icon :icon="['fa', 'lock']"
                          class="icon-course-main"/>
                          {{ entry.meta.title2 }}
                        </p>

                        <span v-else-if="entry.meta.state === 'far fa-circle'">
                          <font-awesome-icon :icon="['far', 'circle']"
                          class="icon-course-main"/>
                          {{ entry.meta.title2 }}
                        </span>

                        <b-tooltip v-else type="is-dark"
                          position="is-bottom" >
                          <p class="ttlcut">
                            <font-awesome-icon :icon="['far', 'check-circle']"
                            class="icon-course-main"/>
                            {{ entry.meta.title }}
                          </p>
                          <template v-slot:content>
                            <b>{{ entry.meta.title }}</b> <br> {{ entry.meta.mouse_over_text }}
                          </template>
                        </b-tooltip>
                      </div>
                      <p class="column ">{{ entry.meta.time }}</p>
                    </div>
                  </li>
                </ul>
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </section>
 </div>
</template>

<script>
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */

import Check from '../pagetypes/Check.vue';
import Cvid from '../pagetypes/Cvid.vue';
import Plainq from '../pagetypes/Plainq.vue';
import Questions from '../pagetypes/Questions.vue';
import PDF from '../pagetypes/PDF.vue';
import JustText from '../pagetypes/JustText.vue';
import PayWall from '../pagetypes/PayWall.vue';
import InExtro from './InExtro.vue';

export default {
  components: {
    Check,
    InExtro,
    Cvid,
    Plainq,
    Questions,
    PDF,
    JustText,
    PayWall,
  },

  data() {
    return {
      leave: false,
      audio_only: false,
      done_train: {
        type: this.$t('course.course-main.end-texts.done-train.type'),
        title: this.$t('course.course-main.end-texts.done-train.title'),
        body: this.$t('course.course-main.end-texts.done-train.body'),
        action: this.$t('course.course-main.end-texts.done-train.action'),
      },
      done_anchor: {
        type: this.$t('course.course-main.end-texts.done-anchor.type'),
        title: this.$t('course.course-main.end-texts.done-anchor.title'),
        body: this.$t('course.course-main.end-texts.done-anchor.body'),
        action: this.$t('course.course-main.end-texts.done-anchor.action'),
      },
      done_trigger: {
        type: this.$t('course.course-main.end-texts.done-trigger.type'),
        title: this.$t('course.course-main.end-texts.done-trigger.title'),
        body: this.$t('course.course-main.end-texts.done-trigger.body'),
        action: this.$t('course.course-main.end-texts.done-trigger.action'),
      },
      train_end: {
        type: this.$t('course.course-main.end-texts.train-end.type'),
        title: this.$t('course.course-main.end-texts.train-end.title'),
        body: this.$t('course.course-main.end-texts.train-end.body'),
        action: this.$t('course.course-main.end-texts.train-end.action'),
      },
      trigger_end: {
        type: this.$t('course.course-main.end-texts.trigger-end.type'),
        title: this.$t('course.course-main.end-texts.trigger-end.title'),
        body: this.$t('course.course-main.end-texts.trigger-end.body'),
        action: this.$t('course.course-main.end-texts.trigger-end.action'),
      },
      te_end: {
        type: this.$t('course.course-main.end-texts.te-end.type'),
        tlte: true,
        title: this.$t('course.course.course-main.end-texts.te-end.title'),
        body: this.$t('course.course.course-main.end-texts.te-end.body'),
        action: this.$t('course.course.course-main.end-texts.te-end.action'),
      },
      columns: [
        {
          field: 'meta.title',
          label: 'Name',
        },
      ],
      draggingRow: null,
      draggingRowIndex: null,
    };
  },

  created() {
    if (this.$route.params.name !== 'new') {
      this.$store.dispatch('Course/load', this.$route.params.name);
    }
  },

  mounted() {
    setTimeout(() => {
      const liId = `li${this.curr_unit}`;
      console.log(liId);
      const element = document.getElementById(liId);
      console.log(element);
      element.scrollIntoView({ block: 'end' });
    }, 300);
  },

  beforeUpdate() {
    if (this.$store.getters['Course/curr_page_nr'] < 0) {
      this.$store.commit('Course/reset_page');
    }
  },

  beforeDestroy() {
    this.$store.dispatch('Course/save');
    this.$store.commit('Course/clear_course');
  },

  computed: {
    loading() {
      return this.$store.getters['Course/loading'];
    },
    user() {
      return this.$store.getters['User/user'];
    },
    type() {
      return this.$store.getters['Course/type'];
    },
    tlte() {
      return (this.type === 'tl' || this.type === 'te');
    },
    live() {
      return this.type === 'live';
    },
    liveStyling() {
      if (this.live) return 'min-height: 12rem;';
      return '';
    },
    istlte() {
      if (this.tlte) {
        return 'disappear';
      }
      return '';
    },
    label_type() {
      if (this.type === 'mindtrigger') return this.$t('common.mindtrigger');
      return this.$t('course.course-main.anchors');
    },
    unit_end_text() {
      if (this.$store.getters['Course/max_unit'] + 1 > this.$store.getters['Course/units'].length) {
        if (this.type === 'mindtrigger') {
          return this.trigger_end;
        }
        if (this.type === 'te') {
          return this.te_end;
        }
        return this.train_end;
      }
      if (this.$store.getters['Course/curr_unit'] < this.$store.getters['Course/princi'].length) {
        if (this.type === 'mindtrigger') {
          const pt = this.done_trigger;
          pt.title = `Trigger ${this.$store.getters['Course/curr_unit']} ${this.$t('course.out-of')} ${this.$store.getters['Course/anchors'].length} ${this.$t('common.status.done')}`;
          return pt;
        }
        const pt = this.done_train;
        pt.title = `${this.$t('common.trainingunit')} ${this.$store.getters['Course/curr_unit']} ${this.$t('course.out-of')} ${this.$store.getters['Course/princi'].length} ${this.$t('common.status.done')}`;
        return pt;
      }
      const pt = this.done_anchor;
      const v = this.$store.getters['Course/curr_unit'] - this.$store.getters['Course/princi'].length;
      if (v === 0) {
        pt.title = this.$t('course.course-main.end-texts.done-anchor.title');
      } else if (this.type === 'mindtrigger') {
        pt.title = `Mindtrigger ${v} ${this.$t('course.out-of')} ${this.$store.getters['Course/anchors'].length} ${this.$t('common.status.done')}`;
        pt.body = `${this.$t('course.course-main.end-texts.trigger-end.text', { days: this.$store.getters['Course/settings'].frequency })}`;
        pt.action = this.$t('course.course-main.end-texts.train-end.action');
      } else {
        pt.title = `${this.$t('common.anchor')} ${v} ${this.$t('course.out-of')} ${this.$store.getters['Course/anchors'].length} ${this.$t('common.status.done')}`;
        pt.body = this.$t('course.course-main.unit_end_text');
      }
      return pt;
    },
    curr_page_type() {
      return this.$store.getters['Course/curr_page_type'];
    },
    curr_page() {
      return this.$store.getters['Course/curr_page'];
    },
    curr_unit() {
      return this.$store.getters['Course/curr_unit'];
    },
    units_length() {
      return this.$store.getters['Course/units'].length;
    },
    curr_course() {
      return this.$store.getters['Course/curr_course'];
    },
    princ() {
      return this.$store.getters['Course/princi'];
    },
    anchors() {
      return this.$store.getters['Course/anchors'];
    },
    curr_course_type() {
      return this.$store.getters['Course/curr_course_type'];
    },
    curr_unit_obj() {
      return this.$store.getters['Course/curr_unit_obj'];
    },
    train_done() {
      return this.$store.getters['Course/train_done'];
    },
    no_anchors() {
      return this.anchors.length === 0;
    },
    isAdmin() {
      return this.$store.getters['User/is_admin'];
    },
    client() {
      return this.$store.getters['User/client'];
    },
  },

  methods: {
    s() {
      this.$store.dispatch('Questions/save');
    },
    logout() {
      this.$router.push({ path: `/${this.$store.getters['User/client'].login_param}` });
      return this.$store.commit('User/logout');
    },
    u_ended() {
      this.$store.commit('Course/end_course');
      this.$store.dispatch('Course/save');
      if (this.type === 'mindtrigger') {
        setTimeout(() => {
          this.$router.push('/dashboard');
        }, 800);
      }
      const liId = `li${this.curr_unit}`;
      const element = document.getElementById(liId);
      element.scrollIntoView({ block: 'center' });
    },
    p_ended() {
      this.leave = true;
      setTimeout(() => {
        this.$store.commit('Course/end_page');
        this.$store.dispatch('Course/save');
      }, 800);
    },
    p_back() {
      this.leave = true;
      setTimeout(() => { this.$store.commit('Course/page_back'); }, 800);
    },
    selectChapter(entry) {
      if (entry.meta.state === 'far fa-check-circle') {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Diese Einheit wurde bereits bearbeitet. Die Inhalte können im Lern-Logbuch eingesehen werden.',
          type: 'is-dark',
        });
      }
      if (entry.meta.state === 'fa fa-lock') {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Diese Trainingseinheit ist noch nicht für sie verfügbar. <br />Bitte bearbeiten sie die Vorherige.',
          type: 'is-dark',
        });
      }
    },
    showMsg(msg) {
      this.$buefy.snackbar.open({
        position: 'is-top-right',
        type: 'is-primary',
        message: msg,
      });
    },
    changeLeave() {
      this.leave = false;
    },
    animation() {
      if (this.leave) {
        setTimeout(() => { this.changeLeave(); }, 800);
        return 'scale-out-center back';
      }
      return 'slide-in-blurred-top back';
    },
  },
};
</script>

<style lang="scss" theme="a">

.course-hero {
  min-height: 70vh;
  background-color: #F6F7F8 !important;

  .modul-title {
    margin-top: 4rem;
    @media only screen and (max-width: 1216px){
      margin-top: 2rem;
    }
    @media only screen and (max-width: 768px){
      margin-top: 1rem;
    }
  }

  .content {
    padding-top: 1.85em;
  }

  .client_logo {
    display: inline-block;
    float: right;
    margin-right: 1rem;
    margin-top: 2.7rem;
    @media only screen and (max-width: 510px) {
      margin-top: 0rem;
      margin-left: 100rem;
    }
    @media only screen and (max-width: 648px) {
      width:200px !important;
    }
    @media only screen and (max-width: 965px) {
      width:250px;
    }
    @media only screen and (min-width: 966px) {
      width:350px;
    }
  }

  .inline {
    display: inline-block;
  }

  .icon-course-main {
    color: var(--primary-color);
    margin-right: 5px;
  }

  .is-primary {
    background-color: var(--primary-color);
    color: var(--primary-color-text);
  }

  .button {
    margin: 0.5rem;
    font-family: "Noto Sans";
    font-weight: 900;
  }

  .tab {
    font-family: "Noto Sans";
    font-weight: 600;
    padding-top: 1rem;
    .tab-content {
      padding: 0;
      margin-left: 0.1rem;
    }
    a {
      border-bottom-color: var(--secondary-color) !important;
      border-bottom-width: 1px;
      color: rgb(41, 41, 41);
    }
    ul {
      border-bottom-color: var(--secondary-color) !important;
      border-bottom-width: 2px;
      font-weight: 700;
      font-size: 1.1rem;
      margin-bottom: 0rem;
    }
  }

  .tabs {
    margin-bottom: 0 !important;
    a:hover {
      border-bottom-color: var(--secondary-color) !important;
    }
    li.is-active a {
      background-color: transparent !important;
      color: black !important;
      border-bottom-width: 2px !important;
      border-bottom-color: var(--secondary-color) !important;
    }
  }

  .new-unit {
    margin: 1rem;
  }

  .topcolumn {
    margin-top: 0;
    padding-bottom: 2rem;
    min-height: 480px;
  }

  .column-right {
    padding-top: 0;
    padding-bottom: 0;
    @media only screen and (max-width: 1023px) {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

  .disappear {
    size: 0;
  }

  .column-video {
    padding-top: 1.85rem;
    padding-bottom: 0;
  }

  .back {
    .div {
      background: white;
    }
  }

  .list {
    overflow-y: auto;
    height: 24.9rem;
    letter-spacing: -.8px;
    font-family: "Noto Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem;
    padding-top: 1.5rem !important;
    text-align: left;
    border-bottom-color: var(--primary-color);
    @media only screen and (max-width: 600px) {
      font-size: .9em;
    }
  }

  .ttlcut {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
    white-space: nowrap;
  }

  .tooltip-content {
    white-space: normal !important;
  }

  .b-tooltip {
    display: inherit;
  }

  .time {
    text-align: end;
  }

  .tablist {
    height: 2.6em;
    .column {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  .level {
    flex-shrink: 1;
  }

  .principle-title {
    padding-bottom: 1.4rem;
  }

  .active-principle {
    background-color: rgba($color: #5f5f5f, $alpha: 1.0);
    border-radius: 5px;
  }

  .principle:hover {
    cursor: pointer;
  }
}

//animations

//fading (transition name)

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

//slideIn Blurred class

.slide-in-blurred-top {
  -webkit-animation: slide-in-blurred-top 1s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
  animation: slide-in-blurred-top 1s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

@-webkit-keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    ´filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

//scaleOUT class

.scale-out-center {
  -webkit-animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
}
</style>

<style lang="scss" theme="b">

.course-hero {
  min-height: 70vh;
  background-color: #F6F7F8 !important;

  .modul-title {
    margin-top: 4rem;
    @media only screen and (max-width: 1216px){
      margin-top: 2rem;
    }
    @media only screen and (max-width: 768px){
      margin-top: 1rem;
    }
  }

  .client_logo {
    display: inline-block;
    float: right;
    margin-right: 1rem;
    margin-top: 2.7rem;
    @media only screen and (max-width: 510px) {
      margin-top: 0rem;
      margin-left: 100rem;
    }
    @media only screen and (max-width: 648px) {
      width:200px !important;
    }
    @media only screen and (max-width: 965px) {
      width:250px;
    }
    @media only screen and (min-width: 966px) {
      width:350px;
    }
  }

  .inline {
    display: inline-block;
  }

  .icon-course-main {
    color: var(--primary-color);
    margin-right: 5px;
  }

  .is-primary {
    background-color: var(--primary-color);
    color: var(--primary-color-text);
  }

  .button {
    margin: 0.5rem;
    font-weight: 900;
  }

  .tab {
    font-weight: 600;
    padding-top: 1rem;
    .tab-content {
      padding: 0;
    }
    a {
      border-bottom-color: var(--secondary-color) !important;
      border-bottom-width: 1px;
      color: rgb(41, 41, 41);
    }
    ul {
      border-bottom-color: var(--secondary-color) !important;
      border-bottom-width: 2px;
      font-weight: 700;
      font-size: 1.1rem;
      margin-bottom: 0rem;
    }
  }

  .tabs {
    margin-bottom: 10px !important;
    a:hover {
      border-bottom-color: var(--secondary-color) !important;
    }
    li.is-active a {
      background-color: transparent !important;
      color: black !important;
      border-bottom-width: 2px !important;
      border-bottom-color: var(--secondary-color) !important;
    }
  }

  .new-unit {
    margin: 1rem;
  }

  .topcolumn {
    margin-top: 0;
    padding-bottom: 2rem;
    min-height: 480px;
  }

  .column-right {
    padding-top: 0;
    padding-bottom: 0;
    @media only screen and (max-width: 1023px) {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

  .disappear {
    size: 0;
  }

  .column-video {
    padding-top: 0;
    padding-bottom: 0;
  }

  .list {
    letter-spacing: -.8px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 2rem;
    text-align: left;
    border-bottom-color: var(--primary-color);
    @media only screen and (max-width: 600px) {
      font-size: .9em;
    }
  }

  .ttlcut {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
    white-space: nowrap;
  }

  .b-tooltip {
    display: inherit;
  }

  .time {
    text-align: end;
  }

  .tablist {
    height: 2.6em;
  }

  .level {
    flex-shrink: 1;
  }

  .principle-title {
    padding-bottom: 1.4rem;
  }

  .active-principle {
    background-color: rgba($color: #5f5f5f, $alpha: 1.0);
    border-radius: 5px;
  }

  .principle:hover {
    cursor: pointer;
  }
}

//animations

//fading (transition name)

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

//slideIn Blurred class

.slide-in-blurred-top {
  -webkit-animation: slide-in-blurred-top 1s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
  animation: slide-in-blurred-top 1s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

@-webkit-keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    ´filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

//scaleOUT class

.scale-out-center {
  -webkit-animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
}
</style>
