<template>
  <section class="register">
    <HomeNav />
    <div class="wrapper indent">
      <div class="registration-div">
        <h2 class="subtitle">OnDemand Registrierung</h2>
        <p>
          Sie wollen unser On Demand Portal testen? Kein Problem, tragen Sie
          einfach hier Ihre Daten inkl. Ihres Passworts für unser Portal ein.
          Im Anschluss erhalten Sie eine Bestätigungsmail und direkten Zugang
          zur Testversion unseres Trainings zur wirkungsorientierten Teamführung.
          Klicken Sie einfach auf den Link in der Mail oder direkt auf „Login“
          auf gobran.de und schon kann es losgehen!
        </p>
      </div>
      <div class="columns">
        <div class="column inline-flex">
          <b-field label="Vorname">
            <b-input placeholder="Vorname"
            style="max-width: 500px;"
            type="name"
            v-model="newuser.firstname"
            icon="account"
            autocomplete="on">
            </b-input>
          </b-field>
          <b-field label="Name">
            <b-input placeholder="Name"
            style="max-width: 500px;"
            type="name"
            v-model="newuser.lastname"
            icon="account"
            autocomplete="on">
            </b-input>
          </b-field>
          <b-field label="E-Mail">
            <b-input placeholder="E-Mail"
            style="max-width: 500px;"
            type="email"
            v-model="newuser.u"
            icon="email"
            autocomplete="on">
            </b-input>
          </b-field>
          <b-field label="Passwort">
            <b-input placeholder="Passwort"
            @keyup.native.enter="login()"
            style="max-width: 500px;"
            type="password"
            v-model="newuser.p"
            icon="key"
            autocomplete="on">
            </b-input>
          </b-field>
          <b-field label="Passwort wiederholen">
            <b-input placeholder="Passwort wiederholen"
            @keyup.native.enter="login()"
            style="max-width: 500px;"
            type="password"
            v-model="newuser.passwordcheck"
            icon="key"
            autocomplete="on">
            </b-input>
          </b-field>
        </div>
        <div class="pw-check column">
          <h5><b>Das Passwort muss folgendes beinhalten:</b></h5>
          <h5>8 Zeichen
            <span  v-if="has8" class="icon icon-course-main">
              <font-awesome-icon :icon="['fa', 'check']" />
            </span>
          </h5>
          <h5>mind. 1 Zahl
            <span v-if="hasd" class="icon icon-course-main">
              <font-awesome-icon :icon="['fa', 'check']" />
            </span>
          </h5>
          <h5>mind. 1 Großbuchstaben
            <span v-if="hasuC" class="icon icon-course-main">
              <font-awesome-icon :icon="['fa', 'check']" />
            </span>
          </h5>
          <h5>mind. 1 Kleinbuchstaben
            <span v-if="haslC" class="icon icon-course-main">
              <font-awesome-icon :icon="['fa', 'check']" />
            </span>
          </h5>
          <h5>mind. 1 Sonderzeichen(!,?, ... )
            <span v-if="hassC" class="icon icon-course-main">
              <font-awesome-icon :icon="['fa', 'check']" />
            </span>
          </h5>
          <h5><b>Die Eingaben müssen übereinstimmen.</b>
            <span v-if="matching" class="icon icon-course-main">
              <font-awesome-icon :icon="['fa', 'check']" />
            </span>
          </h5>
          <div class="register-button">
            <Button :disabled="!isValid" @click="register()" :text="'Registrieren und Testen'" />
          </div>
        </div>
      </div>
    </div>
    <CMSFooter />
  </section>
</template>

<script>
import Button from '@/traincom/components/Common/Button.vue';
import CMSFooter from '../components/Home/CMSFooter.vue';
import HomeNav from '../components/Home/HomeNav.vue';

export default {
  components: {
    CMSFooter,
    HomeNav,
    Button,
  },
  data() {
    return {
      showModal: false,
      forgotModal: false,
      registration: false,
      user: '',
      password: '',
      forgot_mail: '',
      newuser: {
        u: '',
        firstname: '',
        lastname: '',
        p: '',
        passwordcheck: '',
      },
      regex: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
      uC: '(?=.*?[A-Z])',
      lC: '(?=.*?[a-z])',
      sC: '(?=.*?[#?!@$%^&*-])',
      d: '(?=.*?[0-9])',
      min8: '.{8,}',
    };
  },

  computed: {
    isValid() {
      if (this.newuser.firstname.length > 1 && this.newuser.lastname.length > 1) {
        if (this.matching && this.pValid) {
          if (this.newuser.u.length > 3) {
            return true;
          }
        }
      }
      return false;
    },
    pValid() {
      if (this.newuser.p.match(this.regex)) {
        return true;
      }
      return false;
    },
    matching() {
      if (this.newuser.p === this.newuser.passwordcheck) {
        if (this.newuser.p === '' || this.newuser.passwordcheck === '') {
          return false;
        }
        return true;
      }
      return false;
    },
    hasuC() {
      if (this.newuser.p.match(this.uC)) {
        return true;
      }
      return false;
    },
    haslC() {
      if (this.newuser.p.match(this.lC)) {
        return true;
      }
      return false;
    },
    hassC() {
      if (this.newuser.p.match(this.sC)) {
        return true;
      }
      return false;
    },
    hasd() {
      if (this.newuser.p.match(this.d)) {
        return true;
      }
      return false;
    },
    has8() {
      if (this.newuser.p.match(this.min8)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    register() {
      this.$store.dispatch('User/register', this.newuser).then((response) => {
        console.log(response);
        if (response) {
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Registrierung abgeschlossen, sie erhalten in Kürze eine E-Mail.',
            type: 'is-success',
          });
        } else {
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Etwas ist schief gelaufen, bitte melden Sie sich bei uns, oder probieren Sie es später erneut.',
            type: 'is-danger',
          });
        }
      });
    },
    toggleModal() {
      this.showModal = !this.showModal;
      this.registration = false;
    },
  },
};
</script>

<style lang="scss">
.register {
  .wrapper {
    margin-top: 8rem;
  }
  .indent {
    margin-left: 6.25rem;
    margin-right: 6.25rem;
    @media only screen and (max-width: 750px){
      margin-right: 3.125rem;
      margin-left: 3.125rem;
    }
    @media only screen and (max-width: 500px){
      margin-right: 1.8rem;
      margin-left: 1.8rem;
    }
  }
  .columns {
    margin: 1rem 0;
  }
  p {
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 1.25rem;
    @media only screen and (max-width: 500px){
      font-size: 1rem;
    }
  }
  h2 {
    margin-bottom: 1rem !important;
    font-size: 2.375rem !important;
    font-weight: 600 !important;
    color: black !important;
    @media only screen and (max-width: 1050px){
      font-size: 2rem !important;
    }
  }
  h5 {
    .icon {
      height: 80%;
    }
  }
  .pw-check {
    position: relative;
  }
  .register-button {
    @media only screen and (min-width: 769px){
      margin-bottom: 0.75rem;
      position: absolute;
      bottom: 0;
      button {
        margin: 0 !important;
      }
    }
    @media only screen and (max-width: 769px){
      margin-left: 0;
    }
    @media only screen and (max-width: 500px){
      margin-top: 1rem;
    }
  }
}
</style>
