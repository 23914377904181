<template>
<div class="dashboard">
  <Navbar />
  <ModulIntro title='Willkommen zu SYSTEMISCH DENKEN.'
  :isCollapsable="true" />
  <Module />
  <Footer />
</div>
</template>

<script>
import Module from '../components/Dashboard/Module.vue';
import ModulIntro from '../components/Dashboard/ModulIntro.vue';
import Navbar from '../../components/Common/Navbar.vue';
import Footer from '../../components/Common/Footer.vue';

export default {
  components: {
    Navbar,
    Footer,
    ModulIntro,
    Module,
  },
};
</script>

<style lang="scss" scoped>

.dashboard {
  background-color: white;
}
</style>
