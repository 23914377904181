<template>
  <section class="just-text container">
    <div :class="edit_class">
      <span v-if="editmode" @click="del_page()" class="del">
        <font-awesome-icon class="icon red" :icon="['fas', 'trash']" />
      </span>
      <h3 v-if="!editmode">{{page.title}}</h3>
      <input v-if="editmode" class="input" v-model="page.title"
      :placeholder="$t('course.just-text.title-edit')">
      <div class="content">
        <vue-editor v-if="editmode" v-model="page.text" class="editor"
        :editor-toolbar="customToolbar">
        </vue-editor>
        <span v-else class="text" v-html="page.text"></span>
      </div>
      <div class="has-text-centered button-box">
        <nav class="level is-mobile nav">
          <div class="level-item">
            <div class="">
              <Button v-if="!editmode" @click="back()"
              :text="$t('common.back')" :disabled="went_back"
              :type="'zoom'"/>
            </div>
          </div>
          <div class="level-item">
            <Button v-if="!editmode" @click="$emit('end')"
            :text="$t('common.continue')" :disabled="min_chars"
            :type="'zoom'"/>
          </div>
        </nav>
      </div>
      <b-field v-if="editmode">
        <b-switch v-model="page.note_activated">
          {{ $t('course.deactivate-notes') }}
        </b-switch>
      </b-field>
    </div>
  </section>
</template>

<script>
import Button from '@/traincom/components/Common/Button.vue';

export default {
  props: ['page', 'editmode'],

  components: {
    Button,
  },
  created() {
    if (this.went_back) {
      for (let i = 0; i < this.page.question.options.length; i += 1) {
        this.page.question.options[i].u = false;
      }
    }
  },
  data() {
    return {
      customToolbar: [
        [{ header: [false, 1, 2] }],
        ['bold', 'italic', 'underline', 'link'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '-1' }, { indent: '+1' }],
      ],
    };
  },
  methods: {
    back() {
      setTimeout(() => {
        this.$emit('back');
      }, 1000);
    },

    next() {
      this.$parent.next();
    },

    del_page() {
      this.$emit('del_page');
    },
  },

  computed: {
    curr_course_type() {
      return this.$store.getters['Course/curr_course_type'];
    },

    edit_class() {
      if (this.editmode) return 'edit-background notification';
      return 'notification';
    },

    went_back() {
      return this.$store.getters['Course/went_back'];
    },

    page_length() {
      return this.$store.getters['Course/get_q_count'];
    },
  },
};
</script>

<style lang="scss" scoped>
.just-text {
  height: 100%;

  .notification {
    padding: 1rem !important;
    height: 100%;
    background: white;
  }

  .edit-background {
    background: whitesmoke !important;
  }

  h1 {
    font-family: "Roboto";
    font-weight: 500;
    color: black;
    font-size: 2.2rem;
  }

  h2 {
    font-family: "Roboto";
    font-weight: 400;
    color: black;
    font-size: 1.2rem;
    padding-bottom: 1rem;
  }

  h3 {
    font-family: "Roboto";
    font-weight: 450;
    color: black;
    font-size: 2rem;
    padding: 1rem 0;
    min-height: 6rem;

    @media only screen and (max-width: 600px) {
      font-size: 1rem;
    }
  }

  h4 {
    font-family: "Roboto";
    color: black;
    font-size: 1rem;
    padding-top: .1rem;
    padding-bottom: .1rem;
    margin-bottom: 2rem;
    @media only screen and (max-width: 600px) {
      font-size: .8rem;
    }
  }

  .answers {
    font-family: "Roboto";
    font-weight: 450;
    color: black;
    font-size: 1.2rem;
    line-height: 2rem;
  }

  .area {
    margin-top: 2rem;
  }

  .answer-box {
    padding-bottom: 1rem;
  }

  .glow-red {
    color: #C0564A;
    border-radius: 10px;
    text-shadow: 1px 1px 3px #C0564A;
  }

  .glow-green {
    color: #00A237;
    border-radius: 10px;
    text-shadow: 1px 1px 3px #00A237;
  }

  .textarea {
    padding-top: 0rem;
    margin-bottom: 1rem;
  }

  // editmode

  h5 {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 1rem;
    color: black;

  }

  .red {
    color: red;
  }

  .icon {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 2rem;
    transition: all .3s;
    :hover {
      cursor: pointer;
      transform: scale(105%);
      transition: all .3s;
    }
  }

  .del {
    float: right;
  }

  .editor {
    color: black;
    margin-top: 1rem;
  }
  .text {
    color: black;
  }
}
</style>
